import React, { useState, useEffect, useContext } from 'react'
import { View, Text, Font } from '@react-pdf/renderer'
import { PNNoteStyle, reportStyle } from '../../CSS/reportStyle'
import formatDate, { formatTime } from '../../Utility/Utility'
import { GoalService } from '../../GoalComponenet/GoalService'

import axios from 'axios'
import { Variables } from '../../Variables'
import HeaderText from './HeaderText'

Font.register({
  family: 'Roboto',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf',
})
Font.register({
  family: 'Doulos SIL',
  src: 'https://cdn.jsdelivr.net/npm/doulos-sil@5.000/fonts/DoulosSIL-R.ttf',
})

const ProgressNTSection2 = ({
  FirstName,
  HeOrShe,
  hisHer,
  userDetails,
  VisitId,
  FullName,
  clientdata,
  POCData,
  mainData,
  licenceData = null,
}) => {
  const [isYes] = useState('Yes')
  const [goalData, setGoalData] = useState([])

  const goalService = new GoalService()

  useEffect(() => {
    //alert(JSON.stringify(mainData))
    //console.log('userDetails', userDetails)
    //console.log('clientdata', clientdata)
    const fetchData = () => {
      goalService
        .getPNGoals(clientdata.Clientid, VisitId)
        .then((dataFromResponse) => {
          //alert(JSON.stringify(dataFromResponse));
          setGoalData(dataFromResponse)
        })
        .catch((error) => {
          console.error('Error fetching data:', error)
        })
    }
    if (VisitId !== null && clientdata.Clientid != null) {
      fetchData()
    }
  }, [clientdata, VisitId])

  const [headerData, setHeaderData] = useState(null)

  useEffect(() => {
    const fetchHeaderData = () => {
      if (!clientdata) return
      const visitdate = formatDate(
        clientdata.Scheduleddateto,
        false,
        false,
      ).toString()
      goalService
        .getHeaderData(VisitId, clientdata.Clientid, visitdate)
        .then((dataFromResponse) => {
          //alert(JSON.stringify(dataFromResponse))
          setHeaderData(dataFromResponse)
        })
        .catch((error) => {
          console.error('Error fetching data:', error)
        })
    }
    //alert(JSON.stringify(PocData))
    fetchHeaderData()
  }, [VisitId])

  const currentDate = new Date()
  const formattedDate = `${
    currentDate.getMonth() + 1
  }/${currentDate.getDate()}/${currentDate.getFullYear()}`
  return (
    <>
      {/* <View style={PNNoteStyle.headerTextContainerText}>
        <Text style={PNNoteStyle.headerText}>
          Date of Follow Up:{' '}
          {clientdata && formatDate(clientdata.Scheduleddatefrom, false, true)}
          {'   '}
          <Text style={PNNoteStyle.headerTextSpace}> </Text>
          Start: {clientdata && formatTime(clientdata.Scheduleddatefrom)}{' '}
          {'   '}
          <Text style={PNNoteStyle.headerTextSpace}> </Text>
          End:{clientdata && formatTime(clientdata.Scheduleddateto)} {'   '}
          <Text style={PNNoteStyle.headerTextSpace}> </Text>
          Visit: {clientdata && clientdata.VisitStatus}
        </Text>
      </View> */}
      <View style={reportStyle.pageSpace}>
        <HeaderText
          clientdata={clientdata}
          VisitId={VisitId}
          FullName={FullName}
        ></HeaderText>

        <View style={PNNoteStyle.TextHeader}>
          {' '}
          <Text style={PNNoteStyle.TextHeaderText}>Progress Summary</Text>
        </View>
        <View style={PNNoteStyle.TextHeader}>
          {' '}
          <Text style={PNNoteStyle.SubHeaderText}>
            Attendance & Participation
          </Text>
        </View>
        <View style={PNNoteStyle.paragraph}>
          <Text>
            {FirstName} currently attends therapy{' '}
            {mainData && mainData.Frequency} times per week and this is the{' '}
            {clientdata.VisitStatus && (
              <Text>
                {clientdata.VisitStatus.match(/\d+(?=\s*of)/)
                  ? clientdata.VisitStatus.match(/\d+(?=\s*of)/)[0]
                  : 'N/A'}
              </Text>
            )}{' '}
            visit of {mainData && mainData.Duration * mainData.Frequency} (Key:
            3+ High, 2 Moderate, 1 Low).{' '}
            {hisHer.charAt(0).toUpperCase() + hisHer.slice(1)} attendance during
            the period was {mainData && mainData.ClientAttendance} and their
            participation was {mainData && mainData.ClientParticipation}.{' '}
            {HeOrShe}
            {mainData && mainData.IsReceivingServicesOutside === isYes
              ? ' is receiving speech therapy services through an outside agency from ' +
                mainData.ReceivingServicesOutsideDesc +
                '.'
              : ' is not receiving speech therapy services through an outside agency.'}
          </Text>
        </View>

        <View style={PNNoteStyle.TextHeader}>
          {' '}
          <Text style={PNNoteStyle.SubHeaderText}>Home Program</Text>
        </View>
        <View style={PNNoteStyle.paragraph}>
          <Text>
            {FirstName} parent/caregiver
            {mainData && mainData.ProgramEstablish === isYes
              ? ' has established a home program ' +
                mainData.ProgramEstablishDesc +
                '.'
              : ' has not yet established a home program.' +
                mainData.ProgramEstablishDesc +
                '.'}{' '}
            They
            {mainData && mainData.Caregiver === isYes
              ? ' have caregiver/coach involvement ' +
                mainData.CaregiverDesc +
                '.'
              : ' do not have adequate caregiver/coach involvement because ' +
                mainData.CaregiverDesc +
                '.'}
          </Text>
        </View>
        <View style={PNNoteStyle.TextHeader}>
          {' '}
          <Text style={PNNoteStyle.SubHeaderText}>
            Overall Progress Since Last Report
          </Text>
        </View>
        <View style={PNNoteStyle.paragraph}>
          <Text>
            Overall, {FirstName}'s prognosis for a positive therapeutic outcome
            is {mainData && mainData.Prognosis}{' '}
            {mainData && mainData.Prognosis === 'Guarded'
              ? 'because of ' + mainData.PrognosisDesc
              : ''}
            . {mainData.ProgressReport}.{' '}
          </Text>
        </View>

        <View style={PNNoteStyle.TextHeader}>
          {' '}
          <Text style={PNNoteStyle.SubHeaderText}>
            Justification for continued therapy
          </Text>
        </View>
        <View style={PNNoteStyle.paragraph}>
          <Text>{mainData.JustificationContinuedTherapy}</Text>
        </View>
        <View style={PNNoteStyle.TextHeader}>
          {' '}
          <Text style={PNNoteStyle.SubHeaderText}>Recommended Treatment</Text>
        </View>
        <View style={PNNoteStyle.paragraph}>
          <Text>
            {mainData && mainData.Frequency}x week for{' '}
            {clientdata.VisitStatus && (
              <Text>
                {clientdata.VisitStatus.match(/\d+(?=\s*of)/)
                  ? clientdata.VisitStatus.match(/\d+(?=\s*of)/)[0]
                  : 'N/A'}
              </Text>
            )}{' '}
            weeks for {headerData && headerData.Treatment.replace(/,/g, ', ')}.{' '}
            CPT code {headerData && headerData.Billing}.
          </Text>
        </View>
        <View style={PNNoteStyle.paragraph}>
          <Text style={{ fontWeight: 'bold', textDecorationLine: 'underline' }}>
            {mainData && mainData.IsReadyDischargeAuthorization === true ? (
              <Text>
                <Text
                  style={{
                    fontWeight: 'bold',
                    textDecorationLine: 'underline',
                  }}
                >
                  {FirstName}
                </Text>
                {
                  ' is ready for discharge at the end of the current authorization for treatment.'
                }
              </Text>
            ) : (
              ''
            )}
          </Text>
        </View>
        <View style={PNNoteStyle.signatureBox}>
          <Text>
            <>Electronically Signed By SLP</>
          </Text>
          <View style={{ marginTop: 5 }}>
            {/* <Text style={{ fontSize: 8 }}>
                  Supervisor Name:{/*  {soapnoteData && soapnoteData.SupervisorName} 
                </Text> */}

            {clientdata && clientdata.IsSLPA === 1 && (
              <>
                <Text>
                  {licenceData && licenceData.SupervisorName
                    ? licenceData.SupervisorName.toUpperCase()
                    : ''}
                </Text>
                <Text style={{ fontSize: 8 }}>
                  License Number: {licenceData && licenceData.licensenumber}
                </Text>
              </>
            )}
            {clientdata && clientdata.IsSLPA !== 1 && (
              <>
                <Text>
                  {clientdata && clientdata.TherapistName.toUpperCase()}
                </Text>
                <Text style={{ fontSize: 8 }}>
                  License Number: {clientdata && clientdata.LicenseNumber}
                </Text>
              </>
            )}

            <Text style={{ fontSize: 8 }}>Signed Date: {formattedDate}</Text>
          </View>
        </View>
      </View>
    </>
  )
}

export default ProgressNTSection2
