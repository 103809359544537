import React, { useState, useEffect, useContext } from 'react'
import { View, Text } from '@react-pdf/renderer'
import { reportStyle } from '../CSS/reportStyle'
import formatDate from '../Utility/Utility'
import axios from 'axios'
import { Variables } from '../Variables'
import HeaderTextContent from './HeaderTextContent'
import { formatTime } from '../Utility/Utility'

const ContentCHA = ({
  FirstName,
  HeOrShe,
  hisHer,
  userDetails,
  VisitId,
  clientdata,
  FullName,
  pocData,
  updateDiagnosis2,
}) => {
  const [isYes] = useState('Yes')
  const [chcGeneral, setChcGeneral] = useState(null)
  const [chcMedical, setChcMedical] = useState(null)

  useEffect(() => {
    if (!VisitId) return
    const General = axios.get(Variables.API_URL + 'CHA/General/' + VisitId)
    const Medical = axios.get(Variables.API_URL + 'CHA/Medical/' + VisitId)
    axios
      .all([General, Medical])
      .then(
        axios.spread((response1, response2) => {
          // Process the responses here
          const data1 = response1.data.Result
          const data2 = response2.data.Result

          if (response1.status === 200) {
            //alert("CHA General" + JSON.stringify(data1));
            setChcGeneral(data1)
          }

          if (response2.status === 200) {
            //alert("CHA Medical" + JSON.stringify(data2));
            setChcMedical(data2)
            updateDiagnosis2(data2.Diagnosis2)
          }
        }),
      )
      .catch((error) => {
        console.error('Error fetching data:', error)
      })
  }, [VisitId, userDetails])

  function countCharacters(text) {
    return text.length
  }
  return (
    <>
      <View style={reportStyle.pageSpace}>
        {/* <View style={reportStyle.headerTextContainerText}>
          <Text style={reportStyle.headerText}>
            Date of Evaluation:{' '}
            {chcGeneral && formatDate(chcGeneral.CreatedDate, false, true)}
            {'   '}
            <Text style={reportStyle.headerTextSpace}> </Text>
            Start: {clientdata && formatTime(clientdata.Scheduleddatefrom)}{' '}
            {'   '}
            <Text style={reportStyle.headerTextSpace}> </Text>
            End: {clientdata && formatTime(clientdata.Scheduleddateto)} {'   '}
          </Text>
        </View> 
        <HeaderTextContent
          clientdata={clientdata}
          FullName={FullName}
          PocData={pocData}
          Diagnosis2={chcMedical && chcMedical.Diagnosis2}
        ></HeaderTextContent>*/}
        <View style={reportStyle.TextHeader}>
          <Text style={reportStyle.TextHeaderText} break>Case History</Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            {FirstName}'s dominant language is{' '}
            {chcGeneral &&
              (chcGeneral.DominantLanguage === 'Other'
                ? chcGeneral.DominantLanguageOther
                : chcGeneral.DominantLanguage)}
            {chcGeneral &&
              chcGeneral.AdditionalLanguages &&
              ', and ' +
                chcGeneral.AdditionalLanguages.replace(/,/g, ', ') +
                ' is spoken in the home.'}{' '}
            {HeOrShe}{' '}
            {chcGeneral && chcGeneral.HasDifficultExpression === isYes
              ? 'has'
              : 'does not have'}{' '}
            difficulty expressing {hisHer} general wants and needs described as{' '}
            {chcGeneral &&
              chcGeneral.DifficultExpressionDescription &&
              chcGeneral.DifficultExpressionDescription}
            . {HeOrShe} also{' '}
            {chcGeneral && chcGeneral.HasDifficultFinding === isYes
              ? 'has'
              : 'does not have'}{' '}
            difficulty finding the right words when speaking, described as{' '}
            {chcGeneral &&
              chcGeneral.DifficultFindingDescription &&
              chcGeneral.DifficultFindingDescription}{' '}
            and finds others{' '}
            {chcGeneral && chcGeneral.HasDifficultUnderstand === isYes
              ? 'have'
              : 'do not have'}{' '}
            difficulty understanding {hisHer},
            {chcGeneral &&
              chcGeneral.DifficultUnderstandDescription &&
              ' because of ' + chcGeneral.DifficultUnderstandDescription}
            .
          </Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            {chcGeneral && chcGeneral.CurrentLivingDescription && (
              <>
                {FirstName}'s current living situation is{' '}
                {chcGeneral.CurrentLivingDescription}
              </>
            )}
          </Text>
        </View>
        {/* <View style={reportStyle.paragraph}>
          <Text>
            {chcGeneral && chcGeneral.HasSocialSkillConcern === isYes && (
              <>
                {FirstName}’s family has the following concerns about {hisHer}{" "}
                social skills: {chcGeneral.SocialSkillConcernDescription}.
              </>
            )}
          </Text>
        </View> */}
        <View style={reportStyle.paragraph}>
          <Text>
            {chcGeneral && chcGeneral.HasSpeechTherapyInPast === isYes ? (
              <>
                {FirstName} has had previous therapy at{' '}
                {chcGeneral.PastSpeechTherapyDescription} and{' '}
                {chcGeneral &&
                chcGeneral.ReceivedPastSpeechTherapyReport === isYes ? (
                  <>has received a report.</>
                ) : (
                  <>has not received a report.</>
                )}
              </>
            ) : (
              <></>
            )}

            {chcGeneral &&
            chcGeneral.IsReceivingOutsideAgencyService === isYes ? (
              <>
                {HeOrShe} is currently receiving speech therapy services through
                an outside agency from {chcGeneral.ReceivingOutsideAgencyWhere}.{' '}
                {HeOrShe} has also worked with the following specialists:{' '}
                {chcGeneral.OtherSpecialistsWorkedWith}.
                {/* and {hasOrHasNot} received a report. */}
              </>
            ) : (
              <>
                {HeOrShe} is not currently receiving speech therapy services
                through an outside agency.
              </>
            )}
          </Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            {chcGeneral && chcGeneral.HasFamilyDefict && (
              <>
                There
                {chcGeneral.HasFamilyDefict === isYes ? ' is ' : ' is not '}a
                family history of speech and language deficits or learning
                difficulties
                {chcGeneral.HasFamilyDefict === isYes && (
                  <>, which includes {chcGeneral.FamilyDefictDescription}</>
                )}
                .
              </>
            )}
          </Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            {chcGeneral && chcGeneral.SeekingSpeachTherapy && (
              <>
                {FirstName}'s is seeking therapy because{' '}
                {chcGeneral && chcGeneral.SeekingSpeachTherapy}{' '}
                {chcGeneral && chcGeneral.SeekingSpeachTherapyOutcome && (
                  <>
                    and their expected outcome is{' '}
                    {chcGeneral.SeekingSpeachTherapyOutcome}.
                  </>
                )}
              </>
            )}
          </Text>
        </View>
        <View style={reportStyle.TextHeader} break>
          <Text style={reportStyle.TextHeaderText}>Medical History</Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            {chcMedical && chcMedical.Diagnosis1 && (
              <>
                {FirstName} was first diagnosed with {chcMedical.Diagnosis1} on{' '}
                {formatDate(chcMedical.Diagnosis1OnsetDate, false, true)}.
              </>
            )}
            {chcMedical && chcMedical.Diagnosis2 && (
              <Text>
                {' '}
                {HeOrShe} was also diagnosed with {chcMedical.Diagnosis2} on{' '}
                {formatDate(chcMedical.Diagnosis2OnsetDate, false, true)}.
              </Text>
            )}
            {chcMedical &&
              chcMedical.Diagnosis3 !== null &&
              chcMedical.Diagnosis3.trim() !== '' && (
                <Text>
                  {' '}
                  {HeOrShe} was also diagnosed with {chcMedical.Diagnosis3} on{' '}
                  {formatDate(chcMedical.Diagnosis3OnsetDate, false, true)}.
                </Text>
              )}
            {chcMedical && chcMedical.Diagnosis4 && (
              <Text>
                {' '}
                {HeOrShe} was also diagnosed with {chcMedical.Diagnosis4} on{' '}
                {formatDate(chcMedical.Diagnosis2OnsetDate, false)}.
              </Text>
            )}
            {/* The resulting speech and language deficit affects {hisHer} life by{" "}
            {DeficitEffect}. */}
          </Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            {chcMedical && chcMedical.HadHeadInjury && (
              <>
                {FirstName}{' '}
                {chcMedical.HadHeadInjury === isYes ? 'has' : 'has not'} had a{' '}
                head injury.{' '}
                {chcMedical.HadHeadInjury === isYes
                  ? 'The injury was described as: ' +
                    chcMedical.HeadInjuryDescription +
                    '. '
                  : ''}
                {HeOrShe}{' '}
                {chcMedical.HasRecentChangeInVoice === isYes
                  ? 'has'
                  : 'has not'}{' '}
                noticed recent changes to their voice
                {chcMedical.HasRecentChangeInVoice === isYes
                  ? ' beginning ' +
                    formatDate(chcMedical.NoticeChange, false, true)
                  : '.'}{' '}
              </>
            )}
          </Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            {chcMedical && chcMedical.DevelopmentalDiagnoses && (
              <>
                {FirstName}'s family reported {hisHer} developmental diagnosis
                as {chcMedical.DevelopmentalDiagnoses}.
              </>
            )}
          </Text>
        </View>

        <View style={reportStyle.paragraph}>
          <Text>
            {FirstName} has{' '}
            {chcMedical && (
              <>
                {chcMedical.HasAllergy === isYes
                  ? 'the following allergies ' +
                    chcMedical.AllergyDescription +
                    '.'
                  : 'no allergies.'}
              </>
            )}{' '}
            {HeOrShe} is currently on{' '}
            {chcMedical && (
              <>
                {chcMedical.IsOnMedication === isYes
                  ? 'the following medications ' +
                    chcMedical.MedicationDescription +
                    '. '
                  : 'no medications.'}
              </>
            )}
            {HeOrShe}{' '}
            {chcMedical && (
              <>
                {chcMedical.PreviousSurgeriesDescription
                  ? 'has had the following surgical ' +
                    chcMedical.PreviousSurgeriesDescription +
                    '.'
                  : 'has had no surgical.'}
              </>
            )}
          </Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            {FirstName}{' '}
            {chcMedical && (
              <>
                {chcMedical.DoesWearGlasses === isYes
                  ? 'wears glasses '
                  : 'does not wear glasses.'}
                {chcMedical.LastVisionScreeningDate && (
                  <>
                    {' '}
                    and they {chcMedical.LastVisionScreeningResult} their latest
                    vision screening on{' '}
                    {formatDate(
                      chcMedical.LastVisionScreeningDate,
                      false,
                      true,
                    )}
                    .
                  </>
                )}
              </>
            )}
          </Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            {FirstName}{' '}
            {chcMedical && (
              <>
                {chcMedical.DoesWearHearingAids === isYes
                  ? 'wears hearing aids '
                  : 'does not wear hearing aids '}
                {chcMedical.LastHearingScreeningDate && (
                  <>
                    {' '}
                    and they {chcMedical.LastHearingScreeningResult} their{' '}
                    latest hearing screening on{' '}
                    {formatDate(
                      chcMedical.LastHearingScreeningDate,
                      false,
                      true,
                    )}
                    .
                  </>
                )}
              </>
            )}
          </Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            {chcMedical && chcMedical.OverallHealthDescription && (
              <>
                {FirstName}'s general overall health was described as{' '}
                {chcMedical.OverallHealthDescription}.
              </>
            )}
          </Text>
        </View>
      </View>
    </>
  )
}

export default ContentCHA
