import React, { useState, useEffect, useContext } from 'react'
import { View, Text, Font, Path, Svg } from '@react-pdf/renderer'
import { SoapNoteStyle, reportStyle } from '../../CSS/reportStyle'
import formatDate, { formatTime } from '../../Utility/Utility'
import { GoalService } from '../../GoalComponenet/GoalService'
import { SoapNoteService } from '../../ProgressNote/SoapNote/SoapNoteService'
import { CPTBillingCodeService } from '../../FCM/Component/CPTBillingCodeService'


import axios from 'axios'
import { Variables } from '../../Variables'
import HeaderText from './HeaderText'

Font.register({
  family: 'Roboto',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf',
})
Font.register({
  family: 'Doulos SIL',
  src: 'https://cdn.jsdelivr.net/npm/doulos-sil@5.000/fonts/DoulosSIL-R.ttf',
})
const apiCoreUrl = Variables.USER_API_URL
const ContentSoapNote = ({
  FirstName,
  HeOrShe,
  hisHer,
  userDetails,
  VisitId,
  FullName,
  clientdata,
  POCData,
  soapData,
  pnData,
  Diagnosis2,
}) => {
  const [isYes] = useState('Yes')
  const [goalData, setGoalData] = useState([])
  const [soapnoteData, setSoapnoteData] = useState()
  const [cptCodeList, setcptCodeList] = useState([])
  const goalService = new GoalService()
  const soapNoteService = new SoapNoteService()
  const cptBillingCode = new CPTBillingCodeService()

  // const fetchCPTCodeListData = async (visitId) => {
  //   if (visitId) {
  //     try {
  //       //alert(VisitId);
  //       const response = await axios.get(
  //         apiCoreUrl + 'SLPBilling/GetBillingChargesData?visitid=' + visitId,
  //       )
  //       const data = response.data.ListResult
  //       setcptCodeList(data)
  //       //alert('data : ' + JSON.stringify(data))
  //     } catch (error) {
  //       console.log('Error fetching user details:', error)
  //     }
  //   }
  // }
  useEffect(() => {
    //alert(VisitId)
    if (!clientdata && !VisitId) return
    //alert(JSON.stringify(clientdata));
    const fetchData = () => {
      goalService
        .getPNGoals(clientdata.Clientid, VisitId)
        .then((dataFromResponse) => {
          //alert(JSON.stringify(dataFromResponse));
          setGoalData(dataFromResponse)
        })
        .catch((error) => {
          console.error('Error fetching data:', error)
        })
    }
    // alert('vv' + JSON.stringify(POCData))
    if (VisitId !== null && clientdata?.Clientid != null) {
      //alert(JSON.stringify("calling this"));
      fetchData()
    }
  }, [clientdata, VisitId])

  useEffect(() => {
    // alert(FullName)
    if (VisitId) {
      cptBillingCode
        .getCPTBillingCode(VisitId)
        .then((data) => {
          setcptCodeList(data)
        })
        .catch((error) => {})
    }
  }, [VisitId])

  useEffect(() => {
    if (!clientdata && !VisitId) return
    if (!soapData) {
      if (clientdata?.Clientid) {
        const fetchData = () => {
          soapNoteService
            .getSoapNoteData(
              VisitId,
              clientdata.Clientid,
              clientdata.VisitTypeId,
            )
            .then((dataFromResponse) => {
              setSoapnoteData(dataFromResponse)
            })
            .catch((error) => {
              console.error('Error fetching soapnote data on pdf', error)
            })
        }
        fetchData()
      }
    } else {
      setSoapnoteData(soapData)
    }
  }, [soapData])

  const LTgoalCounters = {}
  const STgoalCounters = {}

  const getGoalCounter = (GoalCode) => {
    //alert(JSON.stringify(LTgoalCounters));
    if (GoalCode === 'LTG') {
      // LT goals: Use the index + 1
      const value = (LTgoalCounters[GoalCode] || 0) + 1
      LTgoalCounters[GoalCode] = value
      return value
    } else {
      // Other goals: Increment the counter for the specific goal type
      const value = (STgoalCounters[GoalCode] || 0) + 1
      STgoalCounters[GoalCode] = value
      return value
    }
  }

  const currentDate = new Date()
  const formattedDate = `${
    currentDate.getMonth() + 1
  }/${currentDate.getDate()}/${currentDate.getFullYear()}`

  const removeContentBeforeHyphen = (originalString) => {
    const hyphenIndex = originalString.indexOf('-')
    const resultString =
      hyphenIndex !== -1
        ? originalString.substring(hyphenIndex + 1)
        : originalString
    return resultString
  }
  return (
    <>
      <View style={reportStyle.pageSpace}>
        {/* <Svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-check-square"
        viewBox="0 0 16 16"
      >
        {" "}
        <Path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />{" "}
        <Path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z" />{" "}
      </Svg> */}
        {/* <View style={SoapNoteStyle.headerTextContainerText}>
          <Text style={SoapNoteStyle.headerText}>
            Date of Follow Up:{' '}
            {clientdata &&
              formatDate(clientdata.Scheduleddatefrom, false, true)}
            {'   '}
            <Text style={SoapNoteStyle.headerTextSpace}> </Text>
            Start: {clientdata && formatTime(clientdata.Scheduleddatefrom)}{' '}
            {'   '}
            <Text style={SoapNoteStyle.headerTextSpace}> </Text>
            End: {clientdata && formatTime(clientdata.Scheduleddateto)} {'   '}
            <Text style={SoapNoteStyle.headerTextSpace}> </Text>
            Visit: {clientdata && clientdata.VisitStatus}
          </Text>
        </View> */}

        <HeaderText
          clientdata={clientdata}
          VisitId={VisitId}
          FullName={FullName}
          PocData={POCData}
          Diagnosis2={Diagnosis2}
        ></HeaderText>
        <View style={SoapNoteStyle.TextHeader} key='head1'>
          <Text style={SoapNoteStyle.TextHeaderText} key='head2'>
            {FirstName}'s Therapy Goals
          </Text>
        </View>
        <View style={reportStyle.paragraph}>
          {goalData &&
            goalData.map((row, index) => (
              <View id={row.SI_GoalsId} style={{ flexDirection: 'row' }}>
                <View style={{ flex: 1 }} id={index}>
                  {row.SI_GoalsId}
                  <Text
                    style={{
                      fontSize: '8px',
                      width:
                        row.GoalType !== 'LT' ? '100%' : 'calc(100% - 10px)',
                      paddingLeft: row.GoalType !== 'LT' ? '10px' : '0',
                    }}
                  >
                    {row.GoalType === 'LT' ? (
                      <>
                        <Text style={{ fontSize: '8px' }}>
                          LTG - {getGoalCounter(row.GoalCode)}: Within{' '}
                          {row.Duration} {row.Units}, {FirstName}{' '}
                          {row.GoalDescription} {row.Accuracy}
                        </Text>
                        .
                      </>
                    ) : row.GoalType === 'Articulation' ? (
                      <>
                        <Text style={{ fontSize: '8px' }}>
                          STG - {LTgoalCounters.LTG}.
                          {getGoalCounter(row.FCMType)}: Within {row.Duration}{' '}
                          {row.Units}
                        </Text>
                        , given{' '}
                        <Text style={{ fontSize: '8px' }}>{row.Given}</Text>,{' '}
                        {FirstName} will{' '}
                        <Text style={{ fontSize: '8px' }}>
                          {row.GoalDescription}
                        </Text>{' '}
                        the <Text style={{ fontSize: '8px' }}>{row.Sound}</Text>{' '}
                        sound(s) at the{' '}
                        <Text style={{ fontSize: '8px' }}>{row.AtLevel}</Text>{' '}
                        level with{' '}
                        <Text style={{ fontSize: '8px' }}>{row.Accuracy}</Text>{' '}
                        accuracy in{' '}
                        <Text style={{ fontSize: '8px' }}>
                          {row.InSessions}
                        </Text>{' '}
                        sessions.
                      </>
                    ) : row.GoalType === 'Phonology' ? (
                      <>
                        STG - {LTgoalCounters.LTG}.{getGoalCounter(row.FCMType)}
                        : Within{' '}
                        <Text style={{ fontSize: '8px' }}>
                          {row.Duration} {row.Units}
                        </Text>
                        , given{' '}
                        <Text style={{ fontSize: '8px' }}>{row.Given}</Text>,{' '}
                        {FirstName} will{' '}
                        <Text style={{ fontSize: '8px' }}>
                          {row.GoalDescription}
                        </Text>{' '}
                        with{' '}
                        <Text style={{ fontSize: '8px' }}>{row.Accuracy}</Text>{' '}
                        accuracy in{' '}
                        <Text style={{ fontSize: '8px' }}>
                          {row.InSessions}
                        </Text>{' '}
                        sessions.
                      </>
                    ) : row.GoalType === 'General' ? (
                      <>
                        STG - {LTgoalCounters.LTG}.{getGoalCounter(row.FCMType)}
                        : Within{' '}
                        <Text style={{ fontSize: '8px' }}>
                          {row.Duration} {row.Units}
                        </Text>
                        , given{' '}
                        <Text style={{ fontSize: '8px' }}>{row.Given}</Text>,{' '}
                        {FirstName} will{' '}
                        <Text style={{ fontSize: '8px' }}>
                          {row.GoalDescription}
                        </Text>{' '}
                        with{' '}
                        <Text style={{ fontSize: '8px' }}>{row.Accuracy}</Text>{' '}
                        accuracy in{' '}
                        <Text style={{ fontSize: '8px' }}>
                          {row.InSessions}
                        </Text>{' '}
                        sessions.
                      </>
                    ) : (
                      <>
                        {' '}
                        <>
                          STG - {LTgoalCounters.LTG}.
                          {getGoalCounter(row.FCMType)}: Within{' '}
                          <Text style={{ fontSize: '8px' }}>
                            {row.Duration} {row.Units}
                          </Text>
                          , given{' '}
                          <Text style={{ fontSize: '8px' }}>{row.Given}</Text>,{' '}
                          {FirstName} will{' '}
                          <Text style={{ fontSize: '8px' }}>
                            {row.GoalDescription}
                          </Text>{' '}
                          with{' '}
                          <Text style={{ fontSize: '8px' }}>
                            {row.Accuracy}
                          </Text>{' '}
                          accuracy in{' '}
                          <Text style={{ fontSize: '8px' }}>
                            {row.InSessions}
                          </Text>{' '}
                          sessions.
                        </>
                      </>
                    )}
                  </Text>
                </View>
              </View>
            ))}
        </View>

        <View style={SoapNoteStyle.TextHeader}>
          {' '}
          <Text style={SoapNoteStyle.TextHeaderText}>
            Subjective Observations
          </Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text style={{ fontSize: '8px' }}>
            {soapnoteData && soapnoteData.SubjectiveObservation}
          </Text>
        </View>
        <View style={SoapNoteStyle.TextHeader}>
          <Text style={SoapNoteStyle.TextHeaderText}>Objective Findings</Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text style={{ fontSize: '8px' }}>Targeted this session:</Text>
          {soapnoteData &&
            soapnoteData.PNGoalListItems.map(
              (row) =>
                !row.GoalMET &&
                row.IsIndicated && (
                  <View
                    key={row.GoalId}
                    style={{ flexDirection: 'row', marginLeft: '10px' }}
                  >
                    {/* Render the items inside the header */}
                    {row.GoalType === 'LT' ? (
                      <Text style={{ fontSize: '8px' }}>
                        Within {row.Duration} {row.Units}, {FirstName}{' '}
                        {row.GoalDescription} {row.Accuracy} accuracy.
                      </Text>
                    ) : row.GoalType === 'General' ? (
                      <Text style={{ fontSize: '8px' }}>
                        Given{' '}
                        <Text style={{ fontSize: '8px' }}>{row.Given}</Text>,{' '}
                        {FirstName} {row.GoalDescription} with{' '}
                        <Text style={{ fontSize: '8px' }}>{row.Accuracy}</Text>{' '}
                        accuracy in{' '}
                        <Text style={{ fontSize: '8px' }}>
                          {row.InSessions}
                        </Text>{' '}
                        sessions.
                      </Text>
                    ) : row.GoalType === 'Articulation' ? (
                      <Text style={{ fontSize: '8px' }}>
                        Given{' '}
                        <Text style={{ fontSize: '8px' }}>{row.Given}</Text>,{' '}
                        {FirstName} {row.GoalDescription} the{' '}
                        <Text style={{ fontSize: '8px' }}>{row.Sound}</Text>{' '}
                        sound(s) at the{' '}
                        <Text style={{ fontSize: '8px' }}>{row.AtLevel}</Text>{' '}
                        level with{' '}
                        <Text style={{ fontSize: '8px' }}>{row.Accuracy}</Text>{' '}
                        accuracy in{' '}
                        <Text style={{ fontSize: '8px' }}>
                          {row.InSessions}
                        </Text>{' '}
                        sessions.
                      </Text>
                    ) : row.GoalType === 'Phonology' ? (
                      <Text style={{ fontSize: '8px' }}>
                        Given{' '}
                        <Text style={{ fontSize: '8px' }}>{row.Given}</Text>,{' '}
                        {FirstName} {row.GoalDescription} with{' '}
                        <Text style={{ fontSize: '8px' }}>{row.Accuracy}</Text>{' '}
                        accuracy in{' '}
                        <Text style={{ fontSize: '8px' }}>
                          {row.InSessions}
                        </Text>{' '}
                        sessions.
                      </Text>
                    ) : (
                      <Text style={{ fontSize: '8px' }}>No content found.</Text>
                    )}
                  </View>
                ),
            )}
          <Text
            style={{
              fontSize: '8px',
              fontWeight: 'bold',
              fontStyle: 'italic',
            }}
          >
            Not targeted this session:
          </Text>
          {soapnoteData &&
            soapnoteData.PNGoalListItems.map(
              (row) =>
                !row.GoalMET &&
                !row.IsIndicated && (
                  <View
                    key={row.GoalId}
                    style={{ flexDirection: 'row', marginLeft: '10px' }}
                  >
                    {/* Render the items inside the header */}
                    {row.GoalType === 'LT' ? (
                      <Text style={{ fontSize: '8px' }}>
                        Within {row.Duration} {row.Units}, {FirstName}{' '}
                        {row.GoalDescription} {row.Accuracy} accuracy.
                      </Text>
                    ) : row.GoalType === 'General' ? (
                      <Text style={{ fontSize: '8px' }}>
                        Given{' '}
                        <Text style={{ fontSize: '8px' }}>{row.Given}</Text>,{' '}
                        {FirstName} {row.GoalDescription} with{' '}
                        <Text style={{ fontSize: '8px' }}>{row.Accuracy}</Text>{' '}
                        accuracy in{' '}
                        <Text style={{ fontSize: '8px' }}>
                          {row.InSessions}
                        </Text>{' '}
                        sessions.
                      </Text>
                    ) : row.GoalType === 'Articulation' ? (
                      <Text style={{ fontSize: '8px' }}>
                        Given{' '}
                        <Text style={{ fontSize: '8px' }}>{row.Given}</Text>,{' '}
                        {FirstName} {row.GoalDescription} the{' '}
                        <Text style={{ fontSize: '8px' }}>{row.Sound}</Text>{' '}
                        sound(s) at the{' '}
                        <Text style={{ fontSize: '8px' }}>{row.AtLevel}</Text>{' '}
                        level with{' '}
                        <Text style={{ fontSize: '8px' }}>{row.Accuracy}</Text>{' '}
                        accuracy in{' '}
                        <Text style={{ fontSize: '8px' }}>
                          {row.InSessions}
                        </Text>{' '}
                        sessions.
                      </Text>
                    ) : row.GoalType === 'Phonology' ? (
                      <Text style={{ fontSize: '8px' }}>
                        Given{' '}
                        <Text style={{ fontSize: '8px' }}>{row.Given}</Text>,{' '}
                        {FirstName} {row.GoalDescription} with{' '}
                        <Text style={{ fontSize: '8px' }}>{row.Accuracy}</Text>{' '}
                        accuracy in{' '}
                        <Text style={{ fontSize: '8px' }}>
                          {row.InSessions}
                        </Text>{' '}
                        sessions.
                      </Text>
                    ) : (
                      <Text style={{ fontSize: '8px' }}>No content found.</Text>
                    )}
                  </View>
                ),
            )}
        </View>

        {/* <View style={reportStyle.paragraph}>
          {soapnoteData &&
            soapnoteData.PNGoalListItems.map(
              (row) =>
                !row.GoalMET ? (
                  <View key={row.GoalId} style={{ flexDirection: 'row' }}>
                    {row.GoalType === 'LT' ? (
                      <Text style={{ fontSize: '8px' }}>
                        Within {row.Duration} {row.Units}, {FirstName}{' '}
                        {row.GoalDescription} {row.Accuracy} accuracy.
                      </Text>
                    ) : row.GoalType === 'General' ? (
                      <Text style={{ fontSize: '8px' }}>
                        Given{' '}
                        <Text style={{ fontSize: '8px' }}>{row.Given}</Text>,{' '}
                        {FirstName} {row.GoalDescription} with{' '}
                        <Text style={{ fontSize: '8px' }}>{row.Accuracy}</Text>{' '}
                        accuracy in{' '}
                        <Text style={{ fontSize: '8px' }}>
                          {row.InSessions}
                        </Text>{' '}
                        sessions.
                      </Text>
                    ) : row.GoalType === 'Articulation' ? (
                      <Text style={{ fontSize: '8px' }}>
                        Given{' '}
                        <Text style={{ fontSize: '8px' }}>{row.Given}</Text>,{' '}
                        {FirstName} {row.GoalDescription} the{' '}
                        <Text style={{ fontSize: '8px' }}>{row.Sound}</Text>{' '}
                        sound(s) at the{' '}
                        <Text style={{ fontSize: '8px' }}>{row.AtLevel}</Text>{' '}
                        level with{' '}
                        <Text style={{ fontSize: '8px' }}>{row.Accuracy}</Text>{' '}
                        accuracy in{' '}
                        <Text style={{ fontSize: '8px' }}>
                          {row.InSessions}
                        </Text>{' '}
                        sessions.
                      </Text>
                    ) : row.GoalType === 'Phonology' ? (
                      <Text style={{ fontSize: '8px' }}>
                        Given{' '}
                        <Text style={{ fontSize: '8px' }}>{row.Given}</Text>,{' '}
                        {FirstName} {row.GoalDescription} with{' '}
                        <Text style={{ fontSize: '8px' }}>{row.Accuracy}</Text>{' '}
                        accuracy in{' '}
                        <Text style={{ fontSize: '8px' }}>
                          {row.InSessions}
                        </Text>{' '}
                        sessions.
                      </Text>
                    ) : (
                      <Text style={{ fontSize: '8px' }}>No content found.</Text>
                    )}
                  </View>
                ) : null, // If GoalMET is true, render nothing (null)
            )}
        </View> */}

        <View style={SoapNoteStyle.TextHeader}>
          <Text style={SoapNoteStyle.TextHeaderText}>
            Assessment of Progress
          </Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text style={{ fontSize: '8px' }}>
            {soapnoteData && soapnoteData.AssessmentofProgress}
          </Text>
        </View>
        <View style={SoapNoteStyle.TextHeader}>
          <Text style={SoapNoteStyle.TextHeaderText}>
            Plan for Next Session
          </Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text style={{ fontSize: '8px' }}>
            {soapnoteData && soapnoteData.PlanNextSession}
          </Text>
        </View>

        <View style={reportStyle.paragraph}>
          <Text style={reportStyle.paragraph}>
            {POCData && POCData.ReferTo && (
              <Text style={reportStyle.paragraph}>
                {FirstName}'s was referred to{' '}
                {POCData.ReferTo && <>{POCData.ReferTo}</>} for{' '}
                {POCData.ResonforReferal}.
              </Text>
            )}
          </Text>
        </View>

        {pnData && pnData.ReferralNeeded && (
          <>
            <View style={SoapNoteStyle.TextHeader}>
              <Text style={SoapNoteStyle.TextHeaderText}>Referred To</Text>
            </View>
            <View>
              <Text style={reportStyle.paragraph}>
                {FirstName}'s was referred to for{' '}
                {pnData.ReferralNeeded.replace(/,/g, ', ')}{' '}
                {pnData.ReferralDescription}.
              </Text>
            </View>
          </>
        )}
        <View style={SoapNoteStyle.TextHeader}>
          <Text style={SoapNoteStyle.TextHeaderText}>Billing Codes</Text>
        </View>
        <View
          style={[
            reportStyle.paragraph,
            { flexDirection: 'row', flexWrap: 'wrap', paddingLeft: 10 },
          ]}
        >
          {cptCodeList.map((item, index) => (
            <View key={index} style={{ width: '100%' }}>
              <Text style={{ fontWeight: 'bold' }}>
                {index + 1}){' '}
                <Text style={{ fontWeight: 'bold' }}>{item.CPTCode}</Text> -
                {removeContentBeforeHyphen(item.CPTCodeDescription)} -{' '}
                {item.Units}
              </Text>
            </View>
          ))}
        </View>

        {/* <View style={[SoapNoteStyle.footerbox, { paddingTop: 100 }]}> */}
        <View style={SoapNoteStyle.signatureBox}>
          <Text>
            {clientdata && clientdata.IsSLPA === 1 ? (
              <>Electronically Signed By:</>
            ) : (
              <>Electronically Signed By SPEECH THERAPIST:</>
            )}
          </Text>
          <View style={{ marginTop: 5 }}>
            <Text>{clientdata && clientdata.TherapistName.toUpperCase()}</Text>
            {clientdata && clientdata.IsSLPA === 1 && (
              <>
               <Text style={{ fontSize: 8 }}>
                 License Number: {clientdata && clientdata.LicenseNumber}
                </Text>
                <Text style={{ fontSize: 8 }}>
                  Supervisor Name: {soapnoteData && soapnoteData.SupervisorName}
                </Text>
              </>
            )}
            {clientdata && clientdata.IsSLPA !== 1 && (
              <>
                <Text style={{ fontSize: 8 }}>
                 License Number: {clientdata && clientdata.LicenseNumber}
                </Text>
              </>
            )}
            <Text style={{ fontSize: 8 }}>Signed Date: {formattedDate}</Text>
          </View>
        </View>
      </View>
      {/* </View> */}
    </>
  )
}

export default ContentSoapNote
