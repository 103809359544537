import React, { useState, useEffect, useContext } from 'react'
import formatDate from '../../Utility/Utility'
import {
  View,
  Text,
  Font,
  Svg,
  Rect,
  Path,
  Image,
  StyleSheet,
} from '@react-pdf/renderer'
import { PNNoteStyle, reportStyle } from '../../CSS/reportStyle'
//import formatDate, { formatTime } from '../../Utility/Utility'
import { GoalService } from '../../GoalComponenet/GoalService'

import HeaderText from './HeaderText'
import LineChart from '../ChartExample/LineChart'
import LineChartProgress from '../ChartExample/LineChartProgress'
import LineChartProgOneData from '../ChartExample/LineChartProgOneData'

Font.register({
  family: 'Roboto',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf',
})
Font.register({
  family: 'Doulos SIL',
  src: 'https://cdn.jsdelivr.net/npm/doulos-sil@5.000/fonts/DoulosSIL-R.ttf',
})
const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 20,
  },
  image: {
    width: 400, // Adjust the width as needed
    height: 300, // Adjust the height as needed
    marginTop: 10,
  },
})
// const dataGraph = [
//   [10, 30, 10, 30, 10, 30, 10],
//   [20, 40, 60, 80, 60, 40, 20],
//   [40, 60, 80, 100, 80, 60, 40],
//   // [40, 50, 55, 60, 70,80,80],
//   // [40, 30, 20, 10, 5],
//   // [70, 100, 70, 100, 70],
// ];
const ProgressNTSection1 = ({
  FirstName,
  HeOrShe,
  hisHer,
  userDetails,
  VisitId,
  FullName,
  clientdata,
  POCData,
  mainData,
  chartImageUrl,
}) => {
  const [isYes] = useState('Yes')
  const [goalData, setGoalData] = useState([])
  const [graphData, setGraphData] = useState([])
  const [graphProgreSummData, setGraphProgreSummData] = useState([])
  const [uniqueVisits, setUniqueVisits] = useState(0)
  const goalService = new GoalService()
  const [FCMNames, setFCMNames] = useState([])
  const [fcmTypeGroups, setFcmTypeGroups] = useState({})

  // useEffect(() => {
  //   if (!clientdata) return
  //   //alert(JSON.stringify(clientdata));
  //   const fetchData = () => {
  //     goalService
  //       .getPNGoals(clientdata.Clientid, VisitId)
  //       .then((dataFromResponse) => {
  //         setGoalData(dataFromResponse)
  //       })
  //       .catch((error) => {
  //         console.error('Error fetching data:', error)
  //       })
  //   }
  //   if (VisitId !== null && clientdata.Clientid != null) {
  //     fetchData()
  //   }

  //   // const fetchGraphData = () => {
  //   //   //alert(JSON.stringify(clientdata.Scheduleddatefrom))
  //   //   if (!clientdata) return
  //   //   //alert(JSON.stringify(formatDate(clientdata.Scheduleddatefrom,false,false)))
  //   //   goalService
  //   //     .getProgressReportData(
  //   //       clientdata.Clientid,
  //   //       'SN',
  //   //       formatDate(clientdata.Scheduleddatefrom, false, false),
  //   //     )
  //   //     .then((dataFromResponse) => {
  //   //       //console.log("GraphData:"+JSON.stringify(dataFromResponse));
  //   //       // alert(JSON.stringify(dataFromResponse))
  //   //       setGraphData(dataFromResponse)
  //   //     })
  //   //     .catch((error) => {
  //   //       console.error('Error fetching data:', error)
  //   //     })
  //   // }
  //   // fetchGraphData()
  // }, [clientdata, VisitId])
  useEffect(() => {
    if (!clientdata) return

    const fetchData = async () => {
      try {
        const goalsResponse = await goalService.getPNGoals(
          clientdata.Clientid,
          VisitId,
        )
        setGoalData(goalsResponse)

        // const graphResponse = await goalService.getProgressReportData(
        //   clientdata.Clientid,
        //   'SN',
        //   formatDate(clientdata.Scheduleddatefrom, false, false),
        // )
        // //alert(JSON.stringify(graphResponse))
        // setGraphData(graphResponse)

        const graphProgreSummResponse =
          await goalService.getMontlyProgressReportData(
            clientdata.Clientid,
            'PN',
            formatDate(clientdata.Scheduleddatefrom, false, false),
          )
        

        // Extract unique VisitIds
        const visitIds = graphProgreSummResponse.map((item) => item.VisitId)
        const uniqueIds = [...new Set(visitIds)]
        setUniqueVisits(uniqueIds.length)
        const FCMTypeList = graphProgreSummResponse.map((item) => item.FCMType)
        const FCMNames = [...new Set(FCMTypeList)]
        
        setFCMNames(FCMNames)

        // Group data by FCMType
        const groups = graphProgreSummResponse.reduce((acc, item) => {
          if (!acc[item.FCMType]) {
            acc[item.FCMType] = []
          }
          acc[item.FCMType].push(item)
          return acc
        }, {})
        setFcmTypeGroups(groups)

        //alert(JSON.stringify(FCMNames))
        if (uniqueIds && uniqueIds.length > 1) {
          //alert(JSON.stringify(graphProgreSummResponse))
          setGraphProgreSummData(graphProgreSummResponse)
        }
         //alert(JSON.stringify(uniqueIds.length))
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData()
  }, [clientdata, VisitId])
  // useEffect(() => {
  //   if (!clientdata) return
  //   const fetchGraphData = () => {
  //     var dateVisit = formatDate(clientdata.Scheduleddatefrom, false, false)
  //     //alert(JSON.stringify(clientdata.Scheduleddatefrom))
  //     if (!clientdata) return
  //     //alert(JSON.stringify(formatDate(clientdata.Scheduleddatefrom,false,false)))
  //     goalService
  //       .getProgressReportData(clientdata.Clientid, 'PN', dateVisit)
  //       .then((dataFromResponse) => {
  //         //console.log("GraphData:"+JSON.stringify(dataFromResponse));
  //         // alert(JSON.stringify(dateVisit))
  //         setGraphProgreSummData(dataFromResponse)
  //         //alert(JSON.stringify(dataFromResponse))
  //       })
  //       .catch((error) => {
  //         console.error('Error fetching data:', error)
  //       })
  //   }
  //   fetchGraphData()
  // }, [clientdata])

  const currentDate = new Date()
  const formattedDate = `${
    currentDate.getMonth() + 1
  }/${currentDate.getDate()}/${currentDate.getFullYear()}`

  const renderCharts = () => {
    return Object.keys(fcmTypeGroups).map((fcmType) => {
      const data = fcmTypeGroups[fcmType]
      switch (fcmType) {
        case 'SLC':
          return (
            <LineChartProgOneData
              FCMName={'Language Comprehension'}
              key={fcmType}
              dataGraph={data}
            />
          )
        case 'SLE':
          return (
            <LineChartProgOneData
              FCMName={'Language Expression'}
              key={fcmType}
              dataGraph={data}
            />
          )
        case 'FLU':
          return (
            <LineChartProgOneData
              FCMName={'Fluency'}
              key={fcmType}
              dataGraph={data}
            />
          )
        case 'PRAG':
          return (
            <LineChartProgOneData
              FCMName={'Pragmatic'}
              key={fcmType}
              dataGraph={data}
            />
          )
        case 'FEED':
          return (
            <LineChartProgOneData
              FCMName={'Feeding'}
              key={fcmType}
              dataGraph={data}
            />
          )
        case 'AAC':
          return (
            <LineChartProgOneData
              FCMName={'AAC'}
              key={fcmType}
              dataGraph={data}
            />
          )
        case 'VOIC':
          return (
            <LineChartProgOneData
              FCMName={'Voice'}
              key={fcmType}
              dataGraph={data}
            />
          )
        case 'COG':
          return (
            <LineChartProgOneData
              FCMName={'Cognition'}
              key={fcmType}
              dataGraph={data}
            />
          )
        case 'SWAL':
          return (
            <LineChartProgOneData
              FCMName={'Swallowing'}
              key={fcmType}
              dataGraph={data}
            />
          )
        case 'INT':
          return (
            <LineChartProgOneData
              FCMName={'Speech Intelligibility'}
              key={fcmType}
              dataGraph={data}
            />
          )
        default:
          return (
            <LineChartProgOneData
              FCMName={'Report'}
              key={fcmType}
              dataGraph={data}
            />
          )
      }
    })
  }

  return (
    <>
      {/* <View style={PNNoteStyle.headerTextContainerText}>
        <Text style={PNNoteStyle.headerText}>
          Date of Follow Up:{' '}
          {clientdata && formatDate(clientdata.Scheduleddatefrom, false, true)}
          {'   '}
          <Text style={PNNoteStyle.headerTextSpace}> </Text>
          Start: {clientdata && formatTime(clientdata.Scheduleddatefrom)}{' '}
          {'   '}
          <Text style={PNNoteStyle.headerTextSpace}> </Text>
          End:{clientdata && formatTime(clientdata.Scheduleddateto)} {'   '}
          <Text style={PNNoteStyle.headerTextSpace}> </Text>
          Visit: {clientdata && clientdata.VisitStatus}
        </Text>
      </View>{' '} */}
      <View style={reportStyle.pageSpace}>
        <HeaderText
          clientdata={clientdata}
          VisitId={VisitId}
          FullName={FullName}
        ></HeaderText>
        <View style={PNNoteStyle.TextHeader}>
          {' '}
          <Text style={PNNoteStyle.TextHeaderText}>
            {FirstName}'s Therapy Goals
          </Text>
        </View>
        <View style={PNNoteStyle.paragraph}>
          {goalData.map((row, index) => {
            if (row.GoalType === 'LT') {
              return null // Skip rendering for "LT" goals
            }

            return (
              <View key={row.SI_GoalsId} style={{ flexDirection: 'row' }}>
                {row.GoalMET ? (
                  <>
                    <Svg width={10} height={10} style={{ marginRight: '5px' }}>
                      {/* Add an SVG checkmark icon */}
                      <Path
                        d='M1.75 5.5L4.25 8L9.25 3'
                        fill='none'
                        stroke='black'
                        strokeWidth='1.5'
                      />
                    </Svg>{' '}
                    <Text
                      x='12'
                      y='8'
                      style={{ marginRight: '5px' }}
                      fontSize='8'
                      fill='black'
                    >
                      MET{' '}
                    </Text>
                  </>
                ) : (
                  <>
                    <View
                      style={{
                        width: 10,
                        height: 10,
                        borderWidth: 1,
                        borderColor: 'black',
                        marginRight: '5px',
                      }}
                    />
                    <Text
                      fontSize='8'
                      style={{ marginRight: '5px' }}
                      fill='black'
                    >
                      WIP{' '}
                    </Text>
                  </>
                )}

                <View style={{ flex: 1, fontSize: '8px' }}>
                  <Text>
                    {row.GoalType === 'Articulation' && (
                      <>
                        Within{' '}
                        <Text style={{ fontSize: '8px' }}>
                          {row.Duration} {row.Units}
                        </Text>
                        , given{' '}
                        <Text style={{ fontSize: '8px' }}>{row.Given}</Text>,{' '}
                        {FirstName} will{' '}
                        <Text style={{ fontSize: '8px' }}>
                          {row.GoalDescription}
                        </Text>{' '}
                        the <Text style={{ fontSize: '8px' }}>{row.Sound}</Text>{' '}
                        sound(s) at the{' '}
                        <Text style={{ fontSize: '8px' }}>{row.AtLevel}</Text>{' '}
                        level with{' '}
                        <Text style={{ fontSize: '8px' }}>{row.Accuracy}</Text>{' '}
                        accuracy in{' '}
                        <Text style={{ fontSize: '8px' }}>
                          {row.InSessions}
                        </Text>{' '}
                        sessions.
                      </>
                    )}
                    {row.GoalType === 'Phonology' && (
                      <>
                        Within{' '}
                        <Text style={{ fontSize: '8px' }}>
                          {row.Duration} {row.Units}
                        </Text>
                        , given{' '}
                        <Text style={{ fontSize: '8px' }}>{row.Given}</Text>,{' '}
                        {FirstName} will{' '}
                        <Text style={{ fontSize: '8px' }}>
                          {row.GoalDescription}
                        </Text>{' '}
                        with{' '}
                        <Text style={{ fontSize: '8px' }}>{row.Accuracy}</Text>{' '}
                        accuracy in{' '}
                        <Text style={{ fontSize: '8px' }}>
                          {row.InSessions}
                        </Text>{' '}
                        sessions.
                      </>
                    )}
                    {row.GoalType === 'General' && (
                      <>
                        Within{' '}
                        <Text style={{ fontSize: '8px' }}>
                          {row.Duration} {row.Units}
                        </Text>
                        , given{' '}
                        <Text style={{ fontSize: '8px' }}>{row.Given}</Text>,{' '}
                        {FirstName} will{' '}
                        <Text style={{ fontSize: '8px' }}>
                          {row.GoalDescription}
                        </Text>{' '}
                        with{' '}
                        <Text style={{ fontSize: '8px' }}>{row.Accuracy}</Text>{' '}
                        accuracy in{' '}
                        <Text style={{ fontSize: '8px' }}>
                          {row.InSessions}
                        </Text>{' '}
                        sessions.
                      </>
                    )}
                  </Text>
                </View>
              </View>
            )
          })}
        </View>
        <View style={PNNoteStyle.TextHeader}>
          {' '}
          <Text style={PNNoteStyle.TextHeaderText}>
            Progress Since Last Evaluation
          </Text>
        </View>
        <View style={PNNoteStyle.paragraph}>
          <Text>{mainData && mainData.ProgressReport}</Text>
        </View>{' '}
        {/* <View style={{ alignContent: 'center' }}>
          <LineChart dataGraph={graphData}></LineChart>
        </View> */}
        {/* <View style={{ alignContent: 'center' }}> */}
        {graphProgreSummData && graphProgreSummData.length > 0 && (
          <> {renderCharts()}</>
        )}
        {/* {graphProgreSummData && graphProgreSummData.length > 0 && (
          <>
            <LineChartProgOneData
              dataGraph={graphProgreSummData}
            ></LineChartProgOneData>
            <LineChart
              dataGraph={graphProgreSummData}
            ></LineChart>
          </>
        )} */}
        {/* </View> */}
      </View>
    </>
  )
}

export default ProgressNTSection1
