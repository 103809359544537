import React, { useState, useContext, useEffect, useMemo } from 'react'
import { Articulation } from '../GoalComponenet/Articulation'
import { GoalTemplatePhonology } from '../GoalComponenet/GoalTemplatePhonology'
import { GTGeneral } from '../GoalComponenet/GTGeneral'
import { GTDefault } from '../GoalComponenet/GTDefault'
import { CheckboxListInColumn } from '../Controls/CheckBoxList'
import { Variables } from '../Variables'
import axios from 'axios'
import {
  VisitProvider,
  SignLockProvider,
  CreatedByProvider,
} from '../Provider/Visitprovider'
import { useLanguage } from '../Provider/LanguageContext'
import { GoalsService } from '../FCM/Component/GoalsService'
import Loading from '../Controls/Loading'
import { toast } from 'react-toastify'

const apiUrl = Variables.API_URL
export const SpeechIntGoal = React.forwardRef(
  ({ onDataFetched, refreshGoal, IsAdult }, ref) => {
    const VisitId = useContext(VisitProvider)
    const [selectedValues, setSelectedValues] = useState([])
    const [articulationData, setArticulationData] = useState()
    const [phonologyData, setPhonologyData] = useState()
    const [generalData, setGeneralData] = useState()
    const [selectedRowLT, setSelectedRowLT] = useState([])
    const [reFreshGrid, setReFreshGrid] = useState(false)
    const [reFreshPhoGrid, setReFreshPHOGrid] = useState(false)
    const [reFreshGenGrid, setReFreshGenGrid] = useState(false)
    const [loading, setLoading] = useState(true)

    const CreatedBy = useContext(CreatedByProvider)
    const IsSignLock = useContext(SignLockProvider)
    const language = useLanguage()
    const [userLanguage, setuserLanguage] = useState(false)
    const serviceGoal = useMemo(() => new GoalsService(), [])
    const [isDirty, setIsDirty] = useState(false)

    React.useImperativeHandle(ref, () => ({
      handleButtonClick,
    }))
    useEffect(() => {
      setuserLanguage(language.language)
    }, [VisitId])

    const handleRowSelected = (selectedRowData) => {
      setSelectedRowLT(selectedRowData)
    }

    const handleTableDataChange = (newData) => {
      setArticulationData(newData)
    }

    const handlePhonologyChange = (newData) => {
      setPhonologyData(newData)
    }
    const handleGeneralChange = (newData) => {
      setGeneralData(newData)
    }

    const fcmItems = (module, type) => {
      // alert(JSON.stringify(selectedValues))
      // var updatedValues = "";
      // if (type.includes("Articulation") && module) {
      //   updatedValues=selectedValues+",Articulation";
      // }
      // if (type.includes("Phonology") && module) {
      //   updatedValues=selectedValues+",Phonology";
      // }
      // if (type.includes("General") && module) {
      //   updatedValues=selectedValues+",General";
      // }
      // setSelectedValues(updatedValues);
      // alert(JSON.stringify(updatedValues));
    }

    const handleCheckboxChange = (ctrlId, selectedValues) => {
      // alert(selectedValues);
      if (selectedValues.includes('Articulation')) {
        setReFreshGrid(true)
      }
      if (selectedValues.includes('Phonology')) {
        setReFreshPHOGrid(true)
      }
      if (selectedValues.includes('General')) {
        setReFreshGenGrid(true)
      }

      setSelectedValues(selectedValues)
    }

    useEffect(() => {
      //
      serviceGoal
        .getGoalsData(VisitId, 'INT')
        .then((response) => {
          const goalTypesCsv = response.map((item) => item.GoalType).join(', ')
          const filterText = goalTypesCsv.replace(/ /g, '')
          // console.log("test response:", filterText);
          //alert(filterText)
          setSelectedValues(filterText)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          setLoading(false)
          onDataFetched()
          setReFreshGenGrid(true)
          setReFreshGrid(true)
          setReFreshPHOGrid(true)
          //alert(refreshGoal);
        })
    }, [VisitId, serviceGoal])

    useEffect(()=>{
      console.log("test selectedRowLT:", selectedRowLT);
    },[selectedRowLT])

    const validateLTGoalDescriptions = () => {
      const isEmpty = selectedRowLT?.some(
        (row) => !row.GoalDescription || row.GoalDescription.trim() === '',
      )
      return isEmpty
    }

    const handleButtonClick = async(showMsg = true) => {
      //alert(isDirty)
      if (IsSignLock || !isDirty) {
        //alert(isDirty)
        return
      }
      setIsDirty(true);
      if (validateLTGoalDescriptions() && showMsg) {
        toast.error('Please select the NOMS first to save the Goal(s).',{style: { width: '500px' },})
        return
      }
      // const apiCalls = []
      //alert("updatedsave : "+JSON.stringify(selectedRowLT));
      // if (selectedRowLT != null) {
      //   apiCalls.push(
      //     axios.post(apiUrl + "SpeechInt/CreateGoal", selectedRowLT)
      //   );
      // }
      const allData = [
        ...(selectedRowLT || []),
        ...(articulationData || []),
        ...(phonologyData || []),
        ...(generalData || []),
      ]
          // console.log("test response:", allData);
          // console.log("test selectedValues.includes('Articulation'):", selectedValues.includes('Articulation'));
          // console.log("test selectedValues:", selectedValues);
      //alert('updatedsave : ' + JSON.stringify(selectedRowLT))
      if (allData.length > 0) {
        const checkApi = await serviceGoal.saveData(allData)
        setReFreshGrid(true)
        setReFreshPHOGrid(true)
        setReFreshGenGrid(true)
        // console.log("test checkApi:", checkApi);
        if(checkApi[0]?.data.StatusCode == 200){
          toast.success(checkApi[0]?.data.MessageDetail)
          setIsDirty(false);
        }
        else{
          toast.error("Something went wrong, please contact to Admin")
          setIsDirty(false);
        }
        // or setReFreshPHOGrid(true), or setReFreshGenGrid(true), depending on your requirement
      }

      // if (articulationData && articulationData.length > 0) {
      //   apiCalls.push(
      //     axios.post(apiUrl + "SpeechInt/CreateGoal", articulationData)
      //   );
      //   setReFreshGrid(true);
      // }
      // if (phonologyData && phonologyData.length > 0) {
      //   apiCalls.push(
      //     axios.post(apiUrl + "SpeechInt/CreateGoal", phonologyData)
      //   );
      //   setReFreshPHOGrid(true);
      // }
      // if (generalData && generalData.length > 0) {
      //   apiCalls.push(axios.post(apiUrl + "SpeechInt/CreateGoal", generalData));
      //   setReFreshGenGrid(true);
      // }
      // Promise.all([apiCalls])
      //   .then((responses) => {
      //     if (showMsg) {
      //       toast.success('Data saved successfully!')
      //     }
      //   })
      //   .catch((error) => {
      //     console.log('Failed to save data. Please try again.')
      //   })
      //   .finally(() => {
      //     setIsDirty(false)
      //   })
    }
    if (loading) {
      return <Loading />
    }
    return (
      <div style={{ display: 'block', width: '100%', padding: 10 }}>
        <table style={{ width: '100%', padding: 5 }}>
          <tr>
            <td>
              <GTDefault
                CreatedBy={CreatedBy}
                VisitId={VisitId}
                IsrefreshGoal={refreshGoal}
                onRowSelected={handleRowSelected}
                fcmType='INT'
                IsChangeTracking={setIsDirty}
              />
            </td>
          </tr>
          {!IsAdult && (
            <>
              <tr>
                <td>
                  <div style={{ width: '350px' }}>
                    <CheckboxListInColumn
                      typeOption={5}
                      columns={3}
                      rows={1}
                      values={selectedValues}
                      onChange={(selectedValues) =>
                        handleCheckboxChange('FCM_Ids', selectedValues)
                      }
                    />
                  </div>
                </td>
              </tr>
            </>
          )}
          <tr>
            <td>
              {IsAdult ? (
                <GTGeneral
                  onTableDataChange={handleGeneralChange}
                  reFreshGrid={true}
                  VisitId={VisitId}
                  IsAdult={IsAdult}
                  fcmType='INT'
                  CreatedBy={CreatedBy}
                  //hasData={false}
                  hasData={(selectedValues) =>
                    fcmItems(selectedValues, 'General')
                  }
                  IsChangeTracking={setIsDirty}
                />
              ) : (
                <>
                  {selectedValues.includes('General') && (
                    <GTGeneral
                      onTableDataChange={handleGeneralChange}
                      reFreshGrid={reFreshGenGrid}
                      VisitId={VisitId}
                      IsAdult={IsAdult}
                      CreatedBy={CreatedBy}
                      fcmType='INT'
                      hasData={(selectedValues) =>
                        fcmItems(selectedValues, 'General')
                      }
                      IsChangeTracking={setIsDirty}
                    />
                  )}
                  {selectedValues.includes('Articulation') && (
                    <Articulation
                      onTableDataChange={handleTableDataChange}
                      reFreshGrid={reFreshGrid}
                      VisitId={VisitId}
                      userLanguage={userLanguage}
                      IsAdult={IsAdult}
                      CreatedBy={CreatedBy}
                      fcmType='INT'
                      hasData={(selectedValues) =>
                        fcmItems(selectedValues, 'Articulation')
                      }
                      IsChangeTracking={setIsDirty}
                    />
                  )}
                  {selectedValues.includes('Phonology') && (
                    <GoalTemplatePhonology
                      onTableDataChange={handlePhonologyChange}
                      reFreshGrid={reFreshPhoGrid}
                      VisitId={VisitId}
                      CreatedBy={CreatedBy}
                      fcmType='INT'
                      hasData={(selectedValues) =>
                        fcmItems(selectedValues, 'Phonology')
                      }
                      IsChangeTracking={setIsDirty}
                    />
                  )}
                </>
              )}
            </td>
          </tr>
          <tr>
            <td colspan={2} style={{ textAlign: 'right' }}>
              <div style={{ marginTop: '10px' }}>
                <button
                  disabled={IsSignLock || !isDirty}
                  onClick={handleButtonClick}
                  style={{ width: '150px' }}
                  className='btn btn-primary rounded-pill'
                >
                  <b> Save</b>{' '}
                </button>
              </div>
            </td>
          </tr>
        </table>
      </div>
    )
  },
)

export default SpeechIntGoal
