import axios from 'axios'
import { Variables } from '../../Variables'
import PNGoal from '../PNGoalClass'
import { ProgressNotes } from '../../Enum'

export class SoapNoteService {
  constructor() {
    this.defaultFormData = {
      SoapNoteId: 0,
      VisitId: 0,
      ClientId: 0,
      TherapistId: null,
      SubjectiveObservation: '',
      AssessmentofProgress: '',
      PlanNextSession: '',
      IsRequiresObservation: false,
      DisplayNoteTab: 0,
      SupervisorName: null,
      CreatedBy: null,
      UpdatedBy: null,
      StopRequestingAuth: false,
      PNGoalListItems: [],
    }
  }

  getSoapNoteData(VisitId, ClientId, VisitTypeId) {
    const apiUrl = Variables.API_URL //alert("ClientId : "+ClientId);
    //console.log("VT1:"+VisitTypeId);
    //console.log(`${apiUrl}SoapNote/GetSoapNote/${VisitId}/${ClientId}/${VisitTypeId}`);
    return axios
      .get(
        `${apiUrl}SoapNote/GetSoapNote/${VisitId}/${ClientId}/${VisitTypeId}`,
      )
      .then((response) => {
        const dataFromResponse = response.data.Result
        // Map response data to the properties defined in defaultFormData
        const mappedData = {
          SoapNoteId: dataFromResponse.SoapNoteId,
          VisitId: dataFromResponse.VisitId,
          ClientId: dataFromResponse.ClientId,
          TherapistId: dataFromResponse.TherapistId,
          SubjectiveObservation: dataFromResponse.SubjectiveObservation,
          AssessmentofProgress: dataFromResponse.AssessmentofProgress,
          PlanNextSession: dataFromResponse.PlanNextSession,
          IsRequiresObservation: dataFromResponse.IsRequiresObservation,
          StopRequestingAuth: dataFromResponse.StopRequestingAuth,
          DisplayNoteTab: dataFromResponse.DisplayNoteTab,
          SupervisorName: dataFromResponse.SupervisorName,
          CreatedBy: dataFromResponse.CreatedBy,
          UpdatedBy: dataFromResponse.UpdatedBy,
          PNGoalListItems: dataFromResponse.PNGoalListItems.map((goalData) => {
            const goal = new PNGoal()
            goal.PN_GoalsId = goalData.PN_GoalsId
            goal.GoalId = goalData.GoalId
            goal.VisitId = VisitId
            goal.ClientId = ClientId
            goal.IsIndicated = goalData.IsIndicated
            goal.RowId = goalData.RowId
            goal.PN_Goal_Type = ProgressNotes.SoapNote
            goal.GoalType = goalData.GoalType
            goal.GoalCode = goalData.GoalCode
            goal.FCMType = goalData.FCMType
            goal.GoalCategory = goalData.GoalCategory
            goal.GoalDescription = goalData.GoalDescription
            goal.Duration = goalData.Duration
            goal.Units = goalData.Units
            goal.Given = goalData.Given
            goal.Sound = goalData.Sound
            goal.AtLevel = goalData.AtLevel
            goal.Accuracy = goalData.Accuracy
            goal.InSessions = goalData.InSessions
            goal.GoalMET = goalData.GoalMET
            goal.GoalMetDate = goalData.GoalMetDate
            goal.CreatedBy = goalData.CreatedBy
            goal.UpdatedBy = goalData.UpdatedBy
            return goal
          }),
        }
        return mappedData
      })
      .catch((error) => {
        console.error('Error fetching SoapNote data:', error)
        throw error
      })
  }

  insertSoapnoteData(formData) {
    const apiUrl = Variables.API_URL
    return axios
      .post(`${apiUrl}SoapNote/InsertSoapNote`, formData)
      .then((response) => response.data)
      .catch((error) => {
        console.error('Error inserting insertSoapnoteData data:', error)
        throw error
      })
  }

  //this will update the Visit supervisor id, visitdatefrom and visitdateto is saved into visit table with every save click on the notes application

  insertSaveDOS(formData) {
    const apiUrl = Variables.USER_API_URL
    return axios
      .post(`${apiUrl}Visit/SaveDOS`, formData)
      .then((response) => response.data)
      .catch((error) => {
        console.error('Error inserting insertSaveDOS data:', error)
        throw error
      })
  }

  
    getLicenseNoByClient = async (userId, clientId) => {
      try {
        const requestUri = `${Variables.USER_API_URL}UserProfessionalLicense/GetLicenseNoByClient?userId=${userId}&clientId=${clientId}`
  //alert(requestUri);
        const response = await axios.get(requestUri)
       // alert(JSON.stringify(response.data.Result));
        return response.data.Result // Assuming your API response data is structured correctly
      } catch (error) {
        console.error('Error fetching getLicenseNoByClient:', error)
        throw error // Optionally re-throw the error for further handling
      }
    }

  getSupervisors = async (therapistId) => {
    try {
      const supervisorType = 1 // ClinicalSupervisor as per Enums.SupervisorType.ClinicalSupervisor
      const requestUri = `${Variables.USER_API_URL}User/GetAssignedSupervisor/?therapistId=${therapistId}&supervisiorType=${supervisorType}`

      const response = await axios.get(requestUri)

      // Parse and return the API response
      return response.data.ListResult // Assuming your API response data is structured correctly
    } catch (error) {
      console.error('Error fetching clinical supervisors:', error)
      throw error // Optionally re-throw the error for further handling
    }
  }
}
