import React, { useEffect, useState } from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { AppProvider } from './AppContext'
import {
  VisitProvider,
  CreatedByProvider,
  UserTypeIdProvider,
} from './Provider/Visitprovider'
import axios from 'axios'
import { Variables } from './Variables'
import { SignLockProvider } from './Provider/Visitprovider'
import AlertifyMessage from './AlertifyMessage'
import signLockService from './POC/SignLock/Service'
import SupervisorContext from './Provider/Visitprovider'
import Modal from 'react-modal'
import { useGridStatePersistence } from '@mui/x-data-grid/internals'
import { Provider } from 'react-redux'
import store from './store/store'
Modal.setAppElement('#root') // Replace '#root' with your app's root element selector

const coreApiURL = Variables.USER_API_URL
const Root = () => {
  const [visitValue, setVisitValue] = useState() //584187 or 916625
  const [isSignLock, setIsSignLock] = useState(true)
  const [supervisorValue, setSupervisorValue] = useState('')
  const [isValidSession, setIsValidSession] = useState(false)
  const [createdBy, setCreatedBy] = useState()
  const [userTypeId, setUserTypeId] = useState(0)
  const [mode, setMode] = useState('n') //it will use for generate the pdf and drop to a loaction using api

  const fetchValueFromAPI = () => {
    try {
      const queryString = window.location.search
      const queryParams = new URLSearchParams(queryString)
      //alert(queryParams);
      const key = queryParams.get('key')
      const value = queryParams.get('value')
      const requestData = {
        Key: key,
        Value: value,
      }
      //console.log(JSON.stringify(requestData))
      const requestUrl = coreApiURL + 'Login/GetSlpNotesParameters'
      //console.log(JSON.stringify(requestData))
      //alert(JSON.stringify(requestData));
      if (queryString) {
        axios
          .post(requestUrl, requestData)
          .then((response) => {
            const { Userid, Visitid, IsValidSession, Mode } =
              response.data.Result // Destructuring the values from the API response
            // const { Userid, Visitid, IsValidSession, Mode } ={ 
            //   Userid: 200, 
            //   // Visitid: 1640358, //Followup 
            //   // Visitid: 1676362, // Followup  1575488
            //   // Visitid: 1538036, 
            //   // Visitid: 1700162,  //Eval
            //   // Visitid: 1774493,  //Eval
            //   // Visitid: 1761284,  //Eval
            //   // Visitid: 1765169,  //Eval
            //   //Visitid: 1954422,              //now
            //    Visitid: 2070680,              //my name

            //   // Visitid: 1758161, //Eval 
            //   // Visitid: 1786979,  //Eval 
            //   // Visitid: 1914886,  //Eval 
            //   // Visitid: 1654218, //Discharge 
            //   IsValidSession: true, 
            //   Mode: 'N' 
            // };
            if (!IsValidSession) {
              alert('Session is not valid.')
            }
            setVisitValue(Visitid)
            setIsValidSession(IsValidSession)
             //alert(JSON.stringify(Visitid));
            setCreatedBy(Userid)
            setMode(Mode)
            if (Userid) {
              fetch(`${coreApiURL}User/GetUserPersonalInfo/?userId=${Userid}`)
                .then((response) => {
                  if (!response.ok) {
                    throw new Error('Network response was not ok')
                  }
                  return response.json()
                })
                .then((data) => {
                  setUserTypeId(data.UserTypeId)
                  //alert(JSON.stringify())
                  //setUserInfo(data);
                })
                .catch((error) => {
                  console.error('Error fetching user data:', error)
                })
            }
            //alert(Visitid)
          })
          .catch((error) => {
            console.error('Error fetching data from fetchValueFromAPI:', error)
          })
      }
    } catch (error) {
      console.error('Error in code:', error)
    }
  }

  const FetchingSignAndLock = () => {
    signLockService
      .getSignLockData(visitValue)
      .then((response) => {
        //alert(JSON.stringify(response))
        if (response) {
          let isSignedAndLocked = response.IsSignedAndLocked
          isSignedAndLocked = isSignedAndLocked || false
          setIsSignLock(isSignedAndLocked)
        } else {
          setIsSignLock(false)
        }
        // alert("hiih");
      })
      .catch((error) => {
        console.error('Error FetchingSignAndLock:', error)
      })
  }

  useEffect(() => {
    fetchValueFromAPI()
  }, [])

  useEffect(() => {
    if (visitValue) {
      FetchingSignAndLock()
    }
  }, [visitValue])

  if (visitValue === null) {
    return <div>Loading...</div>
  } //{visitValue}

  return (
    <Provider store={store}>
    <AppProvider>
      <VisitProvider.Provider value={visitValue}>
        <CreatedByProvider.Provider value={createdBy}>
          <UserTypeIdProvider.Provider value={userTypeId}>
            <SignLockProvider.Provider value={isSignLock}>
              <SupervisorContext.Provider
                value={{ supervisorValue, setSupervisorValue }}
              >
                {/* Visit Id : {visitValue} */}
                <App pdfMode={mode} refreshSignLock={FetchingSignAndLock} />
                <AlertifyMessage />
              </SupervisorContext.Provider>
            </SignLockProvider.Provider>
          </UserTypeIdProvider.Provider>
        </CreatedByProvider.Provider>
      </VisitProvider.Provider>
    </AppProvider> </Provider>
  )
}

const rootElement = document.getElementById('root')
createRoot(rootElement).render(<Root />)

reportWebVitals()
