import React, { useState, useEffect, useContext, lazy, Suspense } from 'react'
import { Variables } from '../Variables'
import { DynamicFormGrid } from '../Controls/DynamicFormGrid'

import axios from 'axios'
import { VisitProvider, SignLockProvider } from '../Provider/Visitprovider'
import { toast } from 'react-toastify'
import Loading from '../Controls/Loading'
import { CreatedByProvider } from '../Provider/Visitprovider'

const apiUrl = Variables.API_URL
const NOMS = lazy(() => import('../FCM/Component/NOMS'))

export const SpeechIntNoms = React.forwardRef(
  ({ onDataFetched, triggerGoalRefresh,isActiveGoal=false }, ref) => {
    const VisitId = useContext(VisitProvider)
    const CreatedBy = useContext(CreatedByProvider)
    const [selectedRow, setSelectedRow] = useState([])
    const IsSignLock = useContext(SignLockProvider)
    const [isSaving, setIsSaving] = useState(false)
    const [shouldReloadData, setShouldReloadData] = useState(false) // Flag to trigger data reload
    const [gridData, setGridData] = useState([])
    const [loading, setLoading] = useState(true)
    const [isDirty, setIsDirty] = useState(false)
    const [isNomsSelected, setNomsSelected] = useState(false)

    React.useImperativeHandle(ref, () => ({
      handleButtonClick,
    }))

    useEffect(() => {
      //alert(VisitId);
      if(isNomsSelected)
      {
        isActiveGoal(true)
      }
    }, [isNomsSelected])

    useEffect(() => {
      var dataFile = 'data/nomsINT.json'
      fetch(dataFile)
        .then((response) => response.json())
        .then((data) => {
          setGridData(data)
        })
        .catch((error) => {
          console.error('Error reading data:', error)
        })
      // .finally(() => {
      //   setLoading(false)
      //   onDataFetched()
      // })
    }, [])

    const handleRowSelected = (selectedRowData) => {
      setSelectedRow(selectedRowData)
    }

    // useEffect(() => {
    //  handleButtonClick(false);
    // },[selectedRow]);

    useEffect(() => {
      if (VisitId !== null || shouldReloadData) {
        // Check if data should be reloaded
        axios
          .get(`${apiUrl}SpeechInt/GetNoms/${VisitId}/INT`)
          .then((response) => {
            const data = response.data.Result
            //alert(JSON.stringify(data.length));
            if (data.length > 0) {
              setSelectedRow(data)
            }
          })
          .catch((error) => {
            console.error('Error fetching data:', error)
          })
          .finally(() => {
            setShouldReloadData(false)
            setLoading(false)
            onDataFetched()
          })
      }
    }, [VisitId, shouldReloadData])

    const updateVisitId = (selectedRowData, newVisitId) => {
      return selectedRowData.map((row) => {
        let updatedRow = { ...row }
        if ('VisitId' in row && row.VisitId === 0) {
          //console.log("Updating VisitId");
          updatedRow = { ...updatedRow, VisitId: newVisitId }
        }
        if ('FCMType' in row) {
          //console.log("Updating FCMType");
          updatedRow = { ...updatedRow, FCMType: 'INT' }
        }
        if (!('CreatedBy' in row)) {
          updatedRow = { ...updatedRow, CreatedBy: CreatedBy }
        }
        updatedRow = { ...updatedRow, UpdatedBy: CreatedBy }
        return updatedRow
      })
    }

    const handleButtonClick = (showMsg = true) => {
      if (IsSignLock || !isDirty) {
        return
      }

      if (selectedRow != null && !isSaving) {
        setIsSaving(true)
        const updatedSelectedRow = updateVisitId(selectedRow, VisitId)
        //  alert(JSON.stringify(updatedSelectedRow));
        //   return;
        axios
          .post(apiUrl + 'SpeechInt/CreateNoms', updatedSelectedRow)
          .then((response) => {
            //toast.success(response.MessageDetail);
            // if (showMsg) {
            //   alert(response.data.MessageDetail)
            // }
            // console.log("test response:", response.data);
            if(response.data.StatusCode == 200){
              toast.success(response.data.MessageDetail)
            }
            else{
              toast.error("Something went wrong");
            }
            //alert("asdasd");
            triggerGoalRefresh()
            //setSelectedRow(updatedSelectedRow);
            setShouldReloadData(true) // Set the reload flag to true after saving
          })
          .catch((error) => {
            console.error('Error saving data "SpeechInt/CreateNoms":', error)
          })
          .finally(() => {
            setIsSaving(false)
            setIsDirty(false)
          })
      }
    }
    const updateGridData = (newGridData) => {
      if (newGridData && newGridData.length > 0) {
        setGridData(newGridData)
      }
    }
    if (loading) {
      return <Loading />
    }
    return (
      <div style={{ display: 'block', width: 'max', padding: 10 }}>
        <table style={{ width: '100%', padding: 5 }}>
          <tr>
            <td style={{ verticalAlign: 'top' }}>
              <p style={{ fontSize: '12px' }}>
                <b>Instructions:</b> Based on your assessment, please answer the
                following questions about the individual's speech
                intelligibility. Intelligibility is based on listener
                familiarity and length of utterance. For all questions, consider
                chronological age expectations when rating the individual.{' '}
              </p>
              <p style={{ fontSize: '12px' }}>
                <b>Note:</b>{' '}
                <i>
                  Please note that the scale is reversed for the first question
                  only. For example, a score of 0-25% indicates the highest
                  functioning for the first question only.{' '}
                </i>
              </p>
              <Suspense fallback={<Loading />}>
                <NOMS
                  VisitId={VisitId}
                  gridData={gridData}
                  defaultRow={selectedRow}
                  onRowSelected={handleRowSelected}
                  updateGridData={updateGridData}
                  isChangedTracker={setIsDirty}
                  isNomsSelected={setNomsSelected}                
                />
              </Suspense>
              {/* <NOMS
                VisitId={VisitId}
                gridData={gridData}
                defaultRow={selectedRow}
                onRowSelected={handleRowSelected}
                updateGridData={updateGridData}
              /> */}
            </td>
          </tr>
          <tr>
            <td colSpan={2} style={{ textAlign: 'right' }}>
              <div style={{ marginTop: '10px' }}>
                <button
                  style={{ width: '150px' }}
                  disabled={IsSignLock || !isDirty || isSaving}
                  onClick={handleButtonClick}
                  className='btn btn-primary rounded-pill'
                >
                  {isSaving ? <b>Saving...</b> : <b>Save</b>}
                </button>
              </div>
            </td>
          </tr>
        </table>
      </div>
    )
  },
)
