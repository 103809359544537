import React, { useState, useEffect, useContext, useRef } from 'react'
import axios from 'axios'
import { Variables } from '../Variables'
import { max } from 'date-fns'

import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import { CheckboxListInColumn } from '../Controls/CheckBoxList'
import { DDList } from '../Controls/DDList'
import { SelectYesNo } from '../Controls/DDLYesNo'
import { FormGroup } from 'react-bootstrap'
import { Datectrl } from '../Controls/Datectrl'
import { TextareaComponent } from '../Controls/TextareaComponent '
import { CHCMedicalDTO } from '../Model/CasehistoryModel'
import { VisitProvider, SignLockProvider } from '../Provider/Visitprovider'
import formatDate from '../Utility/Utility'
import { useUser, userDetails } from '../Provider/UserContext'
import CHCMedicalPDF from '../PDFGenerator/CHCMedicalPDF'
import { SecondaryDiagnosisCode } from './SecondaryDiagnosisCode'
import { PocService } from '../POC/Service/PocService'
import { AsyncAutoComplete } from '../Controls/MultiSelectDropdown'
import { CreatedByProvider } from '../Provider/Visitprovider'
import Loading from '../Controls/Loading'
import { toast } from 'react-toastify'


const birthHistoryType = 'Complicated'
const isYes = 'Yes'
const apiUrl = Variables.API_URL
const apiUrlSLPCore = Variables.USER_API_URL

export const CasehistoryMedical = React.forwardRef(
  ({ onDataFetched, triggerRefresh }, ref) => {
    const { userFirstName, userDetails } = useUser()
    const VisitId = useContext(VisitProvider)
    const [formData, setFormData] = useState(new CHCMedicalDTO())
    const [refreshForm, setRefreshForm] = useState(false)
    //const [isTextareaInvalid, setIsTextareaInvalid] = useState(false);
    const [icdCode, setIcdCode] = useState()
    const IsSignLock = useContext(SignLockProvider)
    const CreatedBy = useContext(CreatedByProvider)
    const pocService = new PocService()
    const [loading, setLoading] = useState(true)
    const [consolidatedRows, setConsolidatedRows] = useState([])
    // const [apiDataOfDiaCodes, setApiDataOfDiaCodes] = useState([]);
    // const [diagnosisCodeCoreApp, setSiagnosisCodeCoreApp] = useState([]);
    const [isRequiredMessageShown, setIsRequiredMessageShown] = useState(false)
    const [birthHistoryError, setbirthHistoryError] = useState(false)
    const [HadHeadInjuryError, setHadHeadInjuryError] = useState(false)
    const [AllergyDescriptionError, setAllergyDescriptionError] =
      useState(false)
    const [MedicationDescriptionError, setMedicationDescriptionError] =
      useState(false)
    const [primaryDiagnosisDetail, setPrimaryDiagnosisDetail] = useState('')
    const [isDirtySave, setIsDirtySave] = useState(false)

    React.useImperativeHandle(ref, () => ({
      handleFormSubmit,
    }))

    const handleSelect = (selectedValue, dropdownName) => {
      const valueControl = dropdownName
      const updatedFormData = {
        ...formData,
        [valueControl]: selectedValue,
      }

      setFormData(updatedFormData) // Update the formData state
      //alert('handleSelect')
      setIsDirtySave(true)
    }

    const handleTextBoxValueChange = (controlName, value) => {
      const valueControl = controlName
      const updatedFormData = {
        ...formData,
        [valueControl]: value,
      }
      setFormData(updatedFormData)
      //alert('handleTextBoxValueChange')
      //setIsDirtySave(true)
    }
    const handleAutoCompleteChange = (controlName, selectedValue) => {
      const valueOnly = selectedValue.value // Extract the value property
      const valueControl = controlName
      if (valueOnly) {
        const updatedFormData = {
          ...formData,
          [valueControl]: valueOnly,
        }

        // alert("ddl change" + JSON.stringify(updatedFormData));
        setFormData(updatedFormData)
        // alert('handleAutoCompleteChange')
        setIsDirtySave(true)
      }
    }
    const handleCheckboxListChange = (controlName, value) => {
      const valueControl = controlName
      //console.log(":"+value);
      const updatedFormData = {
        ...formData,
        [valueControl]: value,
      }
      setFormData(updatedFormData)
      //alert(JSON.stringify(updatedFormData))
      setIsDirtySave(true)
    }

    const ValidationObject = () => {
      let isValid = true
      if (
        formData.BirthHistoryType === 'Complicated' &&
        (formData.BirthHistoryDescription === null ||
          formData.BirthHistoryDescription.trim() === '')
      ) {
        setbirthHistoryError(true)
        isValid = false
      }

      if (
        formData.HadHeadInjury === 'Yes' &&
        (formData.HeadInjuryDescription === null ||
          formData.HeadInjuryDescription.trim() === '')
      ) {
        setHadHeadInjuryError(true)
        isValid = false
      }

      if (
        formData.HasAllergy === 'Yes' &&
        (formData.AllergyDescription === null ||
          formData.AllergyDescription.trim() === '')
      ) {
        setAllergyDescriptionError(true)
        isValid = false
      }

      if (
        formData.IsOnMedication === 'Yes' &&
        (formData.MedicationDescription === null ||
          formData.MedicationDescription.trim() === '')
      ) {
        setMedicationDescriptionError(true)
        isValid = false
      }

      //alert(isValid);
      return isValid
    }

    useEffect(() => {
      ValidationObject()
    }, [formData])

    const handleFormSubmit = (showMsg = true) => {
      // const isValid = ValidationObject();
      // //alert(isValid);
      // if (!isValid) {
      //   alert("Kindly fill the all required field(s).");
      //   return;
      // }
      //alert(isDirtySave)
      if (IsSignLock || !isDirtySave) {
        return
      }
      formData.VisitId = VisitId
      formData.CreatedBy = CreatedBy
      formData.UpdatedBy = CreatedBy
      // alert(JSON.stringify(formData))
      if (formData.HadEarInfection === isYes) {
        formData.EarInfectionFrequency =
          formData.EarInfectionFrequency === ''
            ? '1-2x yr'
            : formData.EarInfectionFrequency
        formData.HasUsedPETubes =
          formData.HasUsedPETubes === '' ? 'No' : formData.HasUsedPETubes
      }
      if (Array.isArray(formData.AdditionalLanguages)) {
        formData.AdditionalLanguages = formData.AdditionalLanguages.join(',')
      }
      if (Array.isArray(formData.OtherSpecialistsWorkedWith)) {
        formData.OtherSpecialistsWorkedWith =
          formData.OtherSpecialistsWorkedWith.join(',')
      }
      //formData.OtherSpecialistsWorkedWith=formData.OtherSpecialistsWorkedWith.join(',');
      //alert("Save Data" + JSON.stringify(formData));
      // return;
      //console.log(JSON.stringify(formData))
      if (formData.CHC_MedicalId === 0) {
        axios
          .post(apiUrl + 'CaseHistory/CreateMedical', formData)
          .then((response) => {
            setRefreshForm(true)
            
            // if (showMsg) {
              toast.success(response.data.MessageDetail)
              // alert(response.data.MessageDetail)
            // }
            triggerRefresh()
            //console.log('Data saved successfully!');
            // Handle any success actions here, e.g., show a success message to the user
          })
          .catch((error) => {
            console.error('Error saving data:', error)
          })
          .finally(() => {
            setIsDirtySave(false)
          })
      } else {
        //console.log(JSON.stringify(formData));
        axios
          .put(Variables.API_URL + 'CaseHistory/UpdateMedical', formData)
          .then((response) => {
            setRefreshForm(true)
            console.log("test showMsg:", showMsg);
            // if (showMsg) {
              // alert(response.data.MessageDetail)
              toast.success(response.data.MessageDetail)
            // }
            triggerRefresh()
          })
          .catch((error) => {
            console.error(error)
          })
          .finally(() => {
            setIsDirtySave(false)
          })

        const episodeUpdatedData = {
          episodeid: formData.EpisodeId,
          //diagnosisid1: "newDiagnosisId1",
          diagnosisid2: formData.Diagnosis2,
          diagnosisid3: formData.Diagnosis3,
          diagnosisid4: formData.Diagnosis4,
          onsetdate: formData.Diagnosis1OnsetDate,
          Onsetdate2: formData.Diagnosis2OnsetDate,
          Onsetdate3: formData.Diagnosis3OnsetDate,
          Onsetdate4: formData.Diagnosis4OnsetDate,
        }
        // alert(JSON.stringify(episodeUpdatedData));
        //alert(formData.EpisodeId)
        if (formData.EpisodeId) {
          axios
            .put(
              Variables.USER_API_URL + 'Episode/UpdateEpisodeDiagnosis',
              episodeUpdatedData,
            )
            .then((response) => {
              // Handle the response from the server
              console.log(
                'Update successful Episode/UpdateEpisodeDiagnosis:',
                response.data,
              )
            })
            .catch((error) => {
              // Handle errors
              console.error(
                'Error updating data(Episode/UpdateEpisodeDiagnosis):',
                error,
              )
            })
        }
      }
    }

    useEffect(() => {
      if (VisitId) {
        setLoading(true)
        //alert(process.env.REACT_APP_API_URL + 'CaseHistory?visitid='+VisitId);
        axios
          .get(apiUrl + 'CaseHistory/Medical/' + VisitId)
          .then((response) => {
            const data = response.data.Result

            if (response.status === 200) {
              const caseHistoryChildDTO = mapToCaseHistoryChildDTO(data)
              //alert(JSON.stringify(caseHistoryChildDTO));
              setFormData(caseHistoryChildDTO)
            } else {
              setFormData(new CHCMedicalDTO())
            }
          })
          .catch((error) => {
            console.error('Error fetching data:', error)
          })
          .finally(() => {
            //setLoading(false)
            //onDataFetched()
          })

        const icdCodedata = () => {
          setLoading(true)
          pocService
            .getPrimaryDiagnosisCode()
            .then((dataFromResponse) => {
              //const first100Items = dataFromResponse.slice(0,1000);
              const transformedData = dataFromResponse.map((item) => ({
                value: item.Value,
                label: item.Text,
              }))
              //alert(JSON.stringify(dataFromResponse));
              setIcdCode(transformedData)
            })
            .catch((error) => {
              console.error('Error fetching diagnosis data:', error)
            })
            .finally(() => {
              // setLoading(false)
              // onDataFetched()
            })
        }
        icdCodedata()

        if (userDetails) {
          setLoading(true)
          if (userDetails.Clientid) {
            const getDiagnosisFromSLPCore = () => {
              axios
                .get(
                  apiUrlSLPCore +
                    'Episode/GetEpisodeByServiceLine?clientId=' +
                    userDetails.Clientid +
                    '&treatmentTypeId=1',
                )
                .then((response) => {
                  const data = response.data

                  if (response.status === 200) {
                    //alert(JSON.stringify(response))
                    //alert(JSON.stringify(data.length))
                    //alert(JSON.stringify(data.episodeid))
                    if (data) {
                      const episodeId = data.episodeid // Access episodeid from the first element
                      //alert(JSON.stringify(episodeId))
                      setFormData((prevFormData) => {
                        const newData = {
                          ...prevFormData,
                          EpisodeId: episodeId,
                        }
                        if (data.diagnosisid1) {
                          newData.Diagnosis1 = data.diagnosisid1
                        }
                        if (data.diagnosisid2 && data.diagnosisid2 !== '') {
                          newData.Diagnosis2 = data.diagnosisid2
                        }
                        if (data.diagnosisid3 && data.diagnosisid3 !== '') {
                          newData.Diagnosis3 = data.diagnosisid3
                        }
                        if (data.diagnosisid4) {
                          newData.Diagnosis4 = data.diagnosisid4
                        }
                        //alert("mew data"+JSON.stringify(newData));
                        return newData
                      })
                    }
                    //alert(JSON.stringify(data))
                    //console.log(JSON.stringify(data));
                  }
                })
                .catch((error) => {
                  console.error(
                    'Error fetching data CaseHistory/DiagnosisCode/:',
                    error,
                  )
                })
                .finally(() => {
                  setLoading(false)
                  // setTimeout(() => {
                  //   setLoading(false)
                  // }, 8000)
                  onDataFetched()
                })
            }

            getDiagnosisFromSLPCore()
          }
        }
      }
      //alert(JSON.stringify(formData));
    }, [userDetails])

    useEffect(() => {
      if (icdCode && icdCode.length > 0) {
        const valuesWithinParentheses = icdCode
          .filter((item) => item.value === formData.Diagnosis1)
          .map((item) => {
            const regex = /\(([^)]+)\)/
            const matches = item.label.match(regex)
            if (matches) {
              setPrimaryDiagnosisDetail(matches[1])
              //return matches[1];
            }
          })

        //alert(JSON.stringify(valuesWithinParentheses));
        //console.log(valuesWithinParentheses);
      }
    }, [icdCode, formData.Diagnosis1])

    const mapToCaseHistoryChildDTO = (item) => {
      const caseHistoryChildDTO = new CHCMedicalDTO()
      caseHistoryChildDTO.CHC_MedicalId = item.CHC_MedicalId
      caseHistoryChildDTO.VisitId = item.VisitId
      caseHistoryChildDTO.FCM_Ids = item.FCM_Ids
      caseHistoryChildDTO.BirthHistoryType = item.BirthHistoryType
      caseHistoryChildDTO.BirthHistoryDescription = item.BirthHistoryDescription
      caseHistoryChildDTO.HadEarInfection = item.HadEarInfection
      caseHistoryChildDTO.EarInfectionFrequency = item.EarInfectionFrequency
      caseHistoryChildDTO.HasUsedPETubes = item.HasUsedPETubes
      caseHistoryChildDTO.IsUsingPacifier = item.IsUsingPacifier
      caseHistoryChildDTO.PacifierUseFrequency = item.PacifierUseFrequency
      caseHistoryChildDTO.HadHeadInjury = item.HadHeadInjury
      caseHistoryChildDTO.HeadInjuryDescription = item.HeadInjuryDescription
      caseHistoryChildDTO.HasAllergy = item.HasAllergy
      caseHistoryChildDTO.AllergyDescription = item.AllergyDescription
      caseHistoryChildDTO.DevelopmentalDiagnoses = item.DevelopmentalDiagnoses
      caseHistoryChildDTO.BehavioralDiagnoses = item.BehavioralDiagnoses
      caseHistoryChildDTO.PreviousSurgeriesDescription =
        item.PreviousSurgeriesDescription
      caseHistoryChildDTO.OverallHealthDescription =
        item.OverallHealthDescription
      caseHistoryChildDTO.LastVistionScreeningDate = formatDate(
        item.LastVistionScreeningDate,
      )
      caseHistoryChildDTO.LastVistionScreeningResult =
        item.LastVistionScreeningResult
      caseHistoryChildDTO.DoesWearGlasses = item.DoesWearGlasses
      caseHistoryChildDTO.LastHearingScreeningDate = formatDate(
        item.LastHearingScreeningDate,
      )

      caseHistoryChildDTO.Diagnosis1OnsetDate = formatDate(
        item.Diagnosis1OnsetDate,
        false,
      )
      caseHistoryChildDTO.Diagnosis2OnsetDate = formatDate(
        item.Diagnosis2OnsetDate,
        false,
      )
      caseHistoryChildDTO.Diagnosis3OnsetDate = formatDate(
        item.Diagnosis3OnsetDate,
        false,
      )
      caseHistoryChildDTO.Diagnosis4OnsetDate = formatDate(
        item.Diagnosis4OnsetDate,
        false,
      )
      caseHistoryChildDTO.Diagnosis1 = item.Diagnosis1
      caseHistoryChildDTO.Diagnosis2 = item.Diagnosis2
      caseHistoryChildDTO.Diagnosis3 = item.Diagnosis3
      caseHistoryChildDTO.Diagnosis4 = item.Diagnosis4

      caseHistoryChildDTO.LastHearingScreeningResult =
        item.LastHearingScreeningResult
      caseHistoryChildDTO.DoesWearHearingAids = item.DoesWearHearingAids
      caseHistoryChildDTO.IsOnMedication = item.IsOnMedication
      caseHistoryChildDTO.MedicationDescription = item.MedicationDescription

      return caseHistoryChildDTO
    }
    const ddListRef = useRef(null)

    const handleUpdatedRows = (updatedRows) => {
      setConsolidatedRows(updatedRows)
      //alert(JSON.stringify(updatedRows));
    }
    const handleRowDeleted = (deletedRow) => {
      if (!deletedRow || typeof deletedRow.RowId === 'undefined') {
        console.error('Invalid deletedRow:', deletedRow)
        return
      }
    }
    if (loading) {
      return <Loading />
    }
    return (
      <div style={{ display: 'block', width: max, padding: 10 }}>
        <table style={{ width: '100%', paddind: 5 }}>
          <tr>
            <td style={{ width: '50%', verticalAlign: 'top' }}>
              <h6>Medical History</h6>
              <Form>
                <Form.Group as={Row}>
                  <Form.Label size='sm' column lg={5}>
                    {userFirstName}'s prenatal and birth history was?
                  </Form.Label>
                  <Col sm={3}>
                    <DDList
                      dropdownName='BirthHistoryType'
                      defaultValue={formData.BirthHistoryType}
                      onSelect={(selectedValue) =>
                        handleSelect(selectedValue, 'BirthHistoryType')
                      }
                      ddType={1}
                    />
                  </Col>

                  {formData.BirthHistoryType === birthHistoryType && (
                    <Col sm={12}>
                      <TextareaComponent
                        IsChangeTracking={setIsDirtySave}
                        showError={birthHistoryError}
                        maxLength={5000}
                        cols={40}
                        rows={1}
                        controlid='BirthHistoryDescription'
                        defaultValue={formData.BirthHistoryDescription}
                        onTextChange={(value) =>
                          handleTextBoxValueChange(
                            'BirthHistoryDescription',
                            value,
                          )
                        }
                        placeholder={'Please describe...'}
                      />{' '}
                    </Col>
                  )}
                </Form.Group>
                <FormGroup as={Row}>
                  <Form.Label size='sm' column lg={4}>
                    Has {userFirstName} has ear infection?
                  </Form.Label>
                  <Col sm={2}>
                    <SelectYesNo
                      defaultValue={formData.HadEarInfection}
                      onSelect={(selectedValue) =>
                        handleSelect(selectedValue, 'HadEarInfection')
                      }
                    />
                  </Col>
                  <Form.Label size='sm' column lg={1}>
                    {formData.HadEarInfection === isYes && 'How often?'}
                  </Form.Label>

                  {formData.HadEarInfection === isYes && (
                    <Col sm={2}>
                      <DDList
                        dropdownName='EarInfectionFrequency'
                        ref={ddListRef}
                        onSelect={(selectedValue) =>
                          handleSelect(selectedValue, 'EarInfectionFrequency')
                        }
                        defaultValue={formData.EarInfectionFrequency}
                        ddType={17}
                      />
                    </Col>
                  )}

                  <Form.Label size='sm' column lg={1}>
                    {formData.HadEarInfection === isYes && 'PE tubes?'}
                  </Form.Label>

                  {formData.HadEarInfection === isYes && (
                    <Col sm={2}>
                      {' '}
                      <SelectYesNo
                        defaultValue={formData.HasUsedPETubes}
                        onSelect={(selectedValue) =>
                          handleSelect(selectedValue, 'HasUsedPETubes')
                        }
                      >
                        {' '}
                      </SelectYesNo>{' '}
                    </Col>
                  )}
                </FormGroup>
                <FormGroup as={Row}>
                  <Form.Label size='sm' column lg={4}>
                    Does {userFirstName} use a pacifier?
                  </Form.Label>
                  <Col sm={2}>
                    <SelectYesNo
                      defaultValue={formData.IsUsingPacifier}
                      onSelect={(selectedValue) =>
                        handleSelect(selectedValue, 'IsUsingPacifier')
                      }
                    ></SelectYesNo>
                  </Col>
                  <Form.Label size='sm' column lg={2}>
                    {formData.IsUsingPacifier === isYes && 'How often?'}
                  </Form.Label>

                  {formData.IsUsingPacifier === isYes && (
                    <Col sm={4}>
                      <DDList
                        onSelect={(selectedValue) =>
                          handleSelect(selectedValue, 'PacifierUseFrequency')
                        }
                        defaultValue={formData.PacifierUseFrequency}
                        ddType={2}
                      />
                    </Col>
                  )}
                </FormGroup>
                <FormGroup as={Row}>
                  <Form.Label size='sm' column lg={4}>
                    Has {userFirstName} had a head injury?
                  </Form.Label>
                  <Col sm={2}>
                    <SelectYesNo
                      defaultValue={formData.HadHeadInjury}
                      onSelect={(selectedValue) =>
                        handleSelect(selectedValue, 'HadHeadInjury')
                      }
                    ></SelectYesNo>
                  </Col>{' '}
                  {formData.HadHeadInjury === isYes && (
                    <Col sm={12}>
                      <TextareaComponent
                        IsChangeTracking={setIsDirtySave}
                        showError={HadHeadInjuryError}
                        maxLength={5000}
                        cols={40}
                        rows={1}
                        defaultValue={formData.HeadInjuryDescription}
                        onTextChange={(value) =>
                          handleTextBoxValueChange(
                            'HeadInjuryDescription',
                            value,
                          )
                        }
                        placeholder={'Please describe...'}
                      />{' '}
                    </Col>
                  )}
                </FormGroup>
                <FormGroup as={Row}>
                  <Form.Label size='sm' column lg={4}>
                    Does {userFirstName} have any allergies?
                  </Form.Label>
                  <Col sm={2}>
                    <SelectYesNo
                      defaultValue={formData.HasAllergy}
                      onSelect={(selectedValue) =>
                        handleSelect(selectedValue, 'HasAllergy')
                      }
                    ></SelectYesNo>
                  </Col>{' '}
                  {formData.HasAllergy === isYes && (
                    <Col sm={12}>
                      <TextareaComponent
                        IsChangeTracking={setIsDirtySave}
                        showError={AllergyDescriptionError}
                        maxLength={5000}
                        cols={40}
                        rows={1}
                        defaultValue={formData.AllergyDescription}
                        onTextChange={(value) =>
                          handleTextBoxValueChange('AllergyDescription', value)
                        }
                        placeholder={'Please describe...'}
                      />{' '}
                    </Col>
                  )}
                </FormGroup>

                <FormGroup as={Row}>
                  <Form.Label size='sm' column lg={5}>
                    Is {userFirstName} currently on any medications?
                  </Form.Label>
                  <Col sm={2}>
                    <SelectYesNo
                      defaultValue={formData.IsOnMedication || 'No'}
                      onSelect={(selectedValue) =>
                        handleSelect(selectedValue, 'IsOnMedication')
                      }
                    ></SelectYesNo>
                  </Col>

                  {formData.IsOnMedication === isYes && (
                    <Col sm={12}>
                      <TextareaComponent
                        IsChangeTracking={setIsDirtySave}
                        showError={MedicationDescriptionError}
                        maxLength={5000}
                        cols={40}
                        rows={1}
                        //isInvalid={isTextareaInvalid}
                        defaultValue={formData.MedicationDescription}
                        onTextChange={(value) =>
                          handleTextBoxValueChange(
                            'MedicationDescription',
                            value,
                          )
                        }
                        placeholder={'Please describe...'}
                      />{' '}
                    </Col>
                  )}
                </FormGroup>

                <FormGroup as={Row}>
                  <Col sm={12}>
                    Outline {userFirstName}'s previous surgeries (if any):
                    <TextareaComponent
                      IsChangeTracking={setIsDirtySave}
                      maxLength={5000}
                      cols={40}
                      rows={1}
                      defaultValue={formData.PreviousSurgeriesDescription}
                      onTextChange={(value) =>
                        handleTextBoxValueChange(
                          'PreviousSurgeriesDescription',
                          value,
                        )
                      }
                      placeholder={'Please describe...'}
                    />
                  </Col>
                </FormGroup>
                <FormGroup as={Row} className='mb-3'>
                  <Col sm={12}>
                    Is there anything else we should know about {userFirstName}
                    's general overall health?
                    <TextareaComponent
                      IsChangeTracking={setIsDirtySave}
                      maxLength={5000}
                      cols={40}
                      rows={1}
                      defaultValue={formData.OverallHealthDescription}
                      onTextChange={(value) =>
                        handleTextBoxValueChange(
                          'OverallHealthDescription',
                          value,
                        )
                      }
                      placeholder={'Please describe...'}
                    />
                  </Col>
                </FormGroup>
              </Form>
            </td>
            <td style={{ width: '50%', verticalAlign: 'top', paddingLeft: 15 }}>
              <b>Clinical Diagnoses</b>{' '}
              <Form>
                <FormGroup as={Row} className='mb-1'>
                  <Col sm={10}>
                    When was {userFirstName} first diagnosed with primary
                    diagnosis (
                    <b>
                      {userDetails && userDetails.Diagnosisid1} -{' '}
                      {primaryDiagnosisDetail}
                    </b>
                    )?
                  </Col>
                  <Col sm={2}>
                    <Datectrl
                      selectedDate={formData.Diagnosis1OnsetDate}
                      onDateChange={(value) =>
                        handleTextBoxValueChange('Diagnosis1OnsetDate', value)
                      }
                    />
                  </Col>
                </FormGroup>
                <FormGroup as={Row} className='mb-1'>
                  <Col sm={6}>
                    When was {userFirstName} secondary diagnosed{' '}
                    {formData.Diagnosis2 && (
                      <>
                        (<b>{formData.Diagnosis2})</b>
                      </>
                    )}
                    ?
                  </Col>
                  <Col sm={4}>
                    {' '}
                    {/* {!formData.Diagnosis2 && (
                    <> */}
                    <AsyncAutoComplete
                      fetchedData={icdCode}
                      noOptionsMessage='No options found'
                      //handleButtonClick={handleButtonClick}
                      isRequiredMessageShown={isRequiredMessageShown}
                      setIsRequiredMessageShown={setIsRequiredMessageShown}
                      selectedValue={formData.Diagnosis2}
                      defaultValue={formData.Diagnosis2}
                      isMultiSelect={false}
                      setSelectedValue={(value) =>
                        handleAutoCompleteChange('Diagnosis2', value)
                      }
                      IsChangeTracking={setIsDirtySave}
                    />{' '}
                    {/* </>
                  )} */}
                  </Col>
                  <Col sm={2} style={{ textAlign: 'right' }}>
                    <Datectrl
                      selectedDate={formData.Diagnosis2OnsetDate}
                      onDateChange={(value) =>
                        handleTextBoxValueChange('Diagnosis2OnsetDate', value)
                      }
                    />
                  </Col>
                </FormGroup>
                <FormGroup as={Row} className='mb-1'>
                  <Col sm={6}>
                    {' '}
                    When was {userFirstName} third diagnosed{' '}
                    {formData.Diagnosis3 && (
                      <>
                        (<b>{formData.Diagnosis3})</b>
                      </>
                    )}
                    ?
                  </Col>
                  <Col sm={4}>
                    {' '}
                    {/* {!formData.Diagnosis3 && (
                    <> */}
                    <AsyncAutoComplete
                      fetchedData={icdCode}
                      noOptionsMessage='No options found'
                      //   handleButtonClick={handleButtonClick}
                      isRequiredMessageShown={isRequiredMessageShown}
                      setIsRequiredMessageShown={setIsRequiredMessageShown}
                      selectedValue={formData.Diagnosis3}
                      defaultValue={formData.Diagnosis3}
                      isMultiSelect={false}
                      setSelectedValue={(value) =>
                        handleAutoCompleteChange('Diagnosis3', value)
                      }
                      IsChangeTracking={setIsDirtySave}
                    />
                    {/* </>
                  )} */}
                  </Col>
                  <Col sm={2} style={{ textAlign: 'right' }}>
                    <Datectrl
                      selectedDate={formData.Diagnosis3OnsetDate}
                      onDateChange={(value) =>
                        handleTextBoxValueChange('Diagnosis3OnsetDate', value)
                      }
                    />
                  </Col>
                </FormGroup>
                <FormGroup as={Row} className='mb-1'>
                  <Col sm={6}>
                    {' '}
                    When was {userFirstName} fourth diagnosed
                    {formData.Diagnosis4 && (
                      <>
                        (<b>{formData.Diagnosis4})</b>
                      </>
                    )}{' '}
                    ?
                  </Col>
                  <Col sm={4}>
                    {' '}
                    {/* {!formData.Diagnosis4 && (
                    <> */}
                    <AsyncAutoComplete
                      fetchedData={icdCode}
                      noOptionsMessage='No options found'
                      //   handleButtonClick={handleButtonClick}
                      isRequiredMessageShown={isRequiredMessageShown}
                      setIsRequiredMessageShown={setIsRequiredMessageShown}
                      selectedValue={formData.Diagnosis4}
                      defaultValue={formData.Diagnosis4}
                      isMultiSelect={false}
                      setSelectedValue={(value) =>
                        handleAutoCompleteChange('Diagnosis4', value)
                      }
                      IsChangeTracking={setIsDirtySave}
                    />{' '}
                    {/* </>
                  )} */}
                  </Col>
                  <Col sm={2} style={{ textAlign: 'right' }}>
                    <Datectrl
                      selectedDate={formData.Diagnosis4OnsetDate}
                      onDateChange={(value) =>
                        handleTextBoxValueChange('Diagnosis4OnsetDate', value)
                      }
                    />
                  </Col>
                </FormGroup>

                {/* <FormGroup as={Row}>
              <Col sm={9}>
                Has {userFirstName} been formally diagnosed with any other
                conditions?
              </Col>
              <Col sm={2}>
                <b>Date</b>
              </Col>
              <Col sm={1} style={{ textAlign: "right" }}></Col>
            </FormGroup>
            <FormGroup as={Row} className="mb-1">
              {loading ? (
                <p>Loading...</p>
              ) : (
                <>
                  <SecondaryDiagnosisCode
                    apiData={consolidatedRows}
                    onUpdatedRows={handleUpdatedRows}
                    onRowAdded={handleUpdatedRows}
                    onRowDeleted={handleRowDeleted}
                    icdCodeforDDL={icdCode} diagnosisCodes={diagnosisCodeCoreApp}
                  />
                </>
              )}
            </FormGroup> */}
                <FormGroup as={Row} className='mb-1'>
                  <Col sm={6}>
                    Please list {userFirstName}'s Developmental diagnoses:
                    <TextareaComponent
                      IsChangeTracking={setIsDirtySave}
                      maxLength={5000}
                      cols={40}
                      rows={1}
                      defaultValue={formData.DevelopmentalDiagnoses}
                      onTextChange={(value) =>
                        handleTextBoxValueChange(
                          'DevelopmentalDiagnoses',
                          value,
                        )
                      }
                      placeholder={'Please describe...'}
                    />
                  </Col>

                  <Col sm={6}>
                    Please list {userFirstName}'s Behavioral diagnoses:
                    <TextareaComponent
                      IsChangeTracking={setIsDirtySave}
                      maxLength={5000}
                      cols={40}
                      rows={1}
                      defaultValue={formData.BehavioralDiagnoses}
                      onTextChange={(value) =>
                        handleTextBoxValueChange('BehavioralDiagnoses', value)
                      }
                      placeholder={'Please describe...'}
                    />
                  </Col>
                </FormGroup>
              </Form>
              <b>Screening History </b>
              <Form>
                <Form.Group as={Row}>
                  <Form.Label size='sm' column lg={7}>
                    When was {userFirstName}'s last vision screening?
                  </Form.Label>
                  <Col sm={3}>
                    <Datectrl
                      selectedDate={formData.LastVistionScreeningDate}
                      onDateChange={(value) =>
                        handleTextBoxValueChange(
                          'LastVistionScreeningDate',
                          value,
                        )
                      }
                    />
                  </Col>
                  <Col sm={2}>
                    <DDList
                      onSelect={(selectedValue) =>
                        handleSelect(
                          selectedValue,
                          'LastVistionScreeningResult',
                        )
                      }
                      defaultValue={formData.LastVistionScreeningResult}
                      ddType={3}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label size='sm' column lg={10}>
                    Does {userFirstName} wear glasses/contacts?
                  </Form.Label>
                  <Col sm={2}>
                    <SelectYesNo
                      defaultValue={formData.DoesWearGlasses}
                      onSelect={(selectedValue) =>
                        handleSelect(selectedValue, 'DoesWearGlasses')
                      }
                    ></SelectYesNo>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label size='sm' column lg={7}>
                    When was {userFirstName}'s last hearing screening?
                  </Form.Label>
                  <Col sm={3}>
                    <Datectrl
                      selectedDate={formData.LastHearingScreeningDate}
                      onDateChange={(value) =>
                        handleTextBoxValueChange(
                          'LastHearingScreeningDate',
                          value,
                        )
                      }
                    />
                  </Col>
                  <Col sm={2}>
                    <DDList
                      onSelect={(selectedValue) =>
                        handleSelect(
                          selectedValue,
                          'LastHearingScreeningResult',
                        )
                      }
                      defaultValue={formData.LastHearingScreeningResult}
                      ddType={3}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label size='sm' column lg={10}>
                    Does {userFirstName} wear hearing aids?
                  </Form.Label>
                  <Col sm={2}>
                    <SelectYesNo
                      defaultValue={formData.DoesWearHearingAids}
                      onSelect={(selectedValue) =>
                        handleSelect(selectedValue, 'DoesWearHearingAids')
                      }
                    ></SelectYesNo>
                  </Col>
                </Form.Group>
                {/* <h6>Current Medications</h6>
              <Form.Group as={Row} className="mb-3">
                <Form.Label size="sm" column lg={10}>
                  Is {userFirstName} currently on any medications?
                </Form.Label>
                <Col sm={2}>
                <SelectYesNo 
                  defaultValue={formData.IsOnMedication} 
                  onSelect={(selectedValue) => handleSelect(selectedValue, 'IsOnMedication')}
                 ></SelectYesNo>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Col sm={12}>
                  <TextareaComponent  IsChangeTracking={setIsDirtySave} cols={40} rows={1}
                  defaultValue={formData.MedicationDescription}
                  onTextChange={(value) =>handleTextBoxValueChange('MedicationDescription', value)}
                   placeholder={"Please describe..."} />
                </Col>
              </Form.Group> */}
                <Row style={{ padding: 2 }}>
                  <b>Areas to Assess</b>
                </Row>
                <Row style={{ padding: 5 }}>
                  <Col sm={12}>
                    <CheckboxListInColumn
                      values={formData.FCM_Ids}
                      onChange={(selectedValues) =>
                        handleCheckboxListChange('FCM_Ids', selectedValues)
                      }
                      typeOption={6}
                      columns={2}
                      rows={7}
                    ></CheckboxListInColumn>
                  </Col>
                </Row>
              </Form>
            </td>
          </tr>
          <tr>
            <td colspan={2} style={{ textAlign: 'right' }}>
              {/* <div
              style={{
                marginTop: "10px",
                display: "inline-block",
                verticalAlign: "middle",
              }}
            >
              <CHCMedicalPDF />
            </div> */}
              <button
                disabled={IsSignLock || !isDirtySave}
                onClick={handleFormSubmit}
                style={{
                  width: '150px',
                  marginTop: '10px',
                  marginLeft: '10px',
                  display: 'inline-block',
                  verticalAlign: 'middle',
                }}
                className='btn btn-primary rounded-pill'
              >
                <b>Save</b>
              </button>
            </td>
          </tr>
        </table>
      </div>
    )
  },
)
