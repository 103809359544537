import React, { useState, useEffect, useContext } from 'react'
import { View, Text, Font } from '@react-pdf/renderer'
import { reportStyle } from '../CSS/reportStyle'
import { AssessSumService } from '../FCM/Component/AssessSumService'
import { NomsService } from '../FCM/Component/NomsService'
import { CognitionService } from '../FCM/Cognition/CognitionService'
import CheckedBoxIcon from './Component/CheckBoxIcon'
import { UnCheckedBoxIcon } from './Component/CheckBoxIcon'
import {
  MemoryOptions,
  OrientationOptions,
  AttentionOptions,
  ExecutiveFunctioningOptions,
  PragmaticsOptions,
} from '../data/options'

Font.register({
  family: 'Roboto',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf',
})
Font.register({
  family: 'Doulos SIL',
  src: 'https://cdn.jsdelivr.net/npm/doulos-sil@5.000/fonts/DoulosSIL-R.ttf',
})
const capitalizeFirstChar = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

const ContentCOG = ({
  FirstName,
  HeOrShe,
  hisHer,
  userDetails,
  VisitId,
  FullName,
  HeaderFcm,
}) => {
  const [assSumdata, setAssSumdata] = useState([])
  const [rptData, setRptData] = useState(null)
  const [nomsData, setNomsData] = useState([])
  // const assessSumService = new AssessSumService()
  // const nomsService = new NomsService()
  const service = new CognitionService()

  useEffect(() => {
    if (!VisitId) return
    //alert(Variables.API_URL + 'SpeechInt/GetAssSummary/' + VisitId+'/INT');
    service
      .getGetAssSummary(VisitId)
      .then((response) => {
        setAssSumdata(response)
        //alert(JSON.stringify(data));
      })
      .catch((error) => console.log('Error fetching data:', error))

    service
      .getCOGData(VisitId)
      .then((response) => {
        //const data = response.data.Result
        //alert(JSON.stringify(response))
        if (response) {
          setRptData(response)
        }
      })
      .catch((error) => console.log('Error fetching data:', error))

    service
      .getNomsData(VisitId)
      .then((response) => {
        setNomsData(response)
      })
      .catch((error) => console.log('Error fetching data:', error))
  }, [VisitId])

  return (
    <>
      <View style={reportStyle.pageSpace}>
        {HeaderFcm === true && (
          <>
            <View style={reportStyle.TextHeaderTop}>
              <Text style={reportStyle.TextHeaderText}>
                Clinical Assessment
              </Text>
            </View>
            <View style={reportStyle.paragraph}>
              <Text>
                {FirstName} was assessed against the following ASHA Functional
                Communication Measures (FCM):
              </Text>
            </View>
          </>
        )}
        <View style={reportStyle.TextHeader}>
          <Text style={reportStyle.TextHeaderText}>Cognition</Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            Based on the assessment & observations outlined herein, I found{' '}
            {FirstName}'s overall deficit to be{' '}
            {rptData && rptData.OverallRating}, and I believe Skilled Speech
            Therapy{' '}
            {rptData && rptData.IsSkilledTherapyIndicated ? 'is not' : 'is'}{' '}
            indicated for Cognition.
          </Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            I scored the following assessments when evaluating {hisHer} current
            Cognition proficiency:
          </Text>
        </View>
        <View style={reportStyle.container}>
          <View style={reportStyle.tblheaderRow}>
            <Text style={reportStyle.tbldataCellAssessment}>Assessment</Text>
            <Text style={reportStyle.tblheaderCell}>Raw Score</Text>
            <Text style={reportStyle.tblheaderCell}>Std Score</Text>
            <Text style={reportStyle.tblheaderCell}>%ile Rank</Text>
          </View>
          {assSumdata.map((row, index) => (
            <View style={reportStyle.tbldataRow} key={row.RowId}>
              <Text style={reportStyle.tbldataCellAssessment}>
                {row.AssessmentSummaryDesc}
              </Text>
              <Text style={reportStyle.tbldataCellNo}>
                {row.AssessmentSummary_Score}
              </Text>
              <Text style={reportStyle.tbldataCellNo}>
                {row.AssessmentSummary_Code}
              </Text>
              <Text style={reportStyle.tbldataCellNo}>
                {row.AssessmentSummary_Rank}
              </Text>
            </View>
          ))}
        </View>
        {/* {rptData && rptData.NonVerbal && (
          <>
            <View style={reportStyle.paragraph}>
              <Text>{FirstName} is currently Non-verbal.</Text>
            </View>
          </>
        )} */}
        <View style={reportStyle.TextHeader}>
          <Text style={reportStyle.TextSubHeader}>
            Cognition Characteristics:
          </Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            {hisHer.charAt(0).toUpperCase() + hisHer.slice(1)} mental status is{' '}
            {rptData && rptData.MentalStatus} and {HeOrShe}{' '}
            {rptData && rptData.AwarenessDeficits === 'Yes'
              ? 'has'
              : 'does not have'}{' '}
            awareness deficits.
          </Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            {hisHer.charAt(0).toUpperCase() + hisHer.slice(1)} word{' '}
            comprehension is at the {rptData && rptData.WordComprehension}{' '}
            level. {HeOrShe.charAt(0).toUpperCase() + HeOrShe.slice(1)} can{' '}
            follow {rptData && rptData.FollowingDirections} directions and{' '}
            answer {rptData && rptData.YesNoQuestions} yes/no questions.
          </Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text style={{ paddingBottom: 2 }}>
            {HeOrShe.charAt(0).toUpperCase() + HeOrShe.slice(1)} has a proper{' '}
            orientation of:
          </Text>
          <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            {OrientationOptions.map((row, index) => (
              <View style={{ width: '16.666%', marginBottom: 4 }} key={row.id}>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  {rptData &&
                  rptData.Orientation &&
                  rptData.Orientation.toLowerCase()
                    .split(',')
                    .includes(row.label.toLowerCase()) ? (
                    <CheckedBoxIcon />
                  ) : (
                    <UnCheckedBoxIcon />
                  )}

                  <Text style={{ marginLeft: 1, textAlign: 'left' }}>
                    {row.label}
                  </Text>
                </View>
              </View>
            ))}
          </View>
        </View>
        <View style={reportStyle.TextHeader}>
          <Text style={reportStyle.TextSubHeader}>Areas of Concern:</Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text style={{ paddingBottom: 2 }}>Memory : </Text>
          <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            {MemoryOptions.map((row, index) => (
              <View style={{ width: '16.666%', marginBottom: 4 }} key={row.id}>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  {rptData &&
                  rptData.Memory &&
                  rptData.Memory.toLowerCase()
                    .split(',')
                    .includes(row.label.toLowerCase()) ? (
                    <CheckedBoxIcon />
                  ) : (
                    <UnCheckedBoxIcon />
                  )}

                  <Text style={{ marginLeft: 1, textAlign: 'left' }}>
                    {row.label}
                  </Text>
                </View>
              </View>
            ))}
          </View>
        </View>
        {rptData && rptData.MemoryDescription && (
          <>
            <View style={reportStyle.paragraph}>
              <Text>
                {hisHer.charAt(0).toUpperCase() + hisHer.slice(1)} memory can be
                described as : {rptData && rptData.MemoryDescription}
              </Text>
            </View>
          </>
        )}
        <View style={reportStyle.paragraph}>
          <Text style={{ paddingBottom: 2 }}>Attention:</Text>
          <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            {AttentionOptions.map((row, index) => (
              <View style={{ width: '25%', marginBottom: 4 }} key={row.id}>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  {rptData &&
                  rptData.Attention &&
                  rptData.Attention.toLowerCase()
                    .split(',')
                    .includes(row.label.toLowerCase()) ? (
                    <CheckedBoxIcon />
                  ) : (
                    <UnCheckedBoxIcon />
                  )}
                  <Text style={{ marginLeft: 1, textAlign: 'left' }}>
                    {row.label}
                  </Text>
                </View>
              </View>
            ))}
          </View>
          {rptData && rptData.AttentionDescription && (
            <>
              <View>
                <Text>
                  {hisHer.charAt(0).toUpperCase() + hisHer.slice(1)} attention
                  can be described as :{' '}
                  {rptData && rptData.AttentionDescription}
                </Text>
              </View>
            </>
          )}
        </View>
        <View style={reportStyle.paragraph}>
          <Text style={{ paddingBottom: 2 }}>Executive Functioning:</Text>
          <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            {ExecutiveFunctioningOptions.map((row, index) => (
              <View style={{ width: '25%', marginBottom: 4 }} key={row.id}>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  {rptData &&
                  rptData.ExecutiveFunctioning &&
                  rptData.ExecutiveFunctioning.toLowerCase()
                    .split(',')
                    .includes(row.label.toLowerCase()) ? (
                    <CheckedBoxIcon />
                  ) : (
                    <UnCheckedBoxIcon />
                  )}
                  <Text style={{ marginLeft: 1, textAlign: 'left' }}>
                    {row.label}
                  </Text>
                </View>
              </View>
            ))}
          </View>
        </View>
        {rptData && rptData.ExecutiveFunctioningDesc && (
          <>
            <View style={reportStyle.paragraph}>
              <Text>
                {hisHer.charAt(0).toUpperCase() + hisHer.slice(1)} executive
                functioning can be described as :{' '}
                {rptData && rptData.ExecutiveFunctioningDesc}
              </Text>
            </View>
          </>
        )}
        <View style={reportStyle.paragraph}>
          <Text style={{ paddingBottom: 2 }}>Pragmatics:</Text>
          <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            {PragmaticsOptions.map((row, index) => (
              <View style={{ width: '20%', marginBottom: 4 }} key={row.id}>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  {rptData &&
                  rptData.Pragmatics &&
                  rptData.Pragmatics.toLowerCase()
                    .split(',')
                    .includes(row.label.toLowerCase()) ? (
                    <CheckedBoxIcon />
                  ) : (
                    <UnCheckedBoxIcon />
                  )}
                  <Text style={{ marginLeft: 1, textAlign: 'left' }}>
                    {row.label}
                  </Text>
                </View>
              </View>
            ))}
          </View>
          {rptData && rptData.PragmaticsDesc && (
            <>
              <Text>
                {hisHer.charAt(0).toUpperCase() + hisHer.slice(1)} pragmatics
                can be described as : {rptData && rptData.PragmaticsDesc}
              </Text>
            </>
          )}
        </View>
        {rptData && rptData.SpeechCharacteristics && (
          <>
            <View style={reportStyle.TextHeader}>
              <Text style={reportStyle.TextSubHeader}>
                Speech Characteristics :{' '}
              </Text>
            </View>
            <View style={reportStyle.paragraph}>
              <Text>{rptData && rptData.SpeechCharacteristics}</Text>
            </View>
          </>
        )}
        {rptData && rptData.ImpactDailyActivities && (
          <>
            <View style={reportStyle.TextHeader}>
              <Text style={reportStyle.TextSubHeader}>
                Impact on Daily Activities :{' '}
              </Text>
            </View>
            <View style={reportStyle.paragraph}>
              <Text>{rptData && rptData.ImpactDailyActivities}</Text>
            </View>
          </>
        )}
        <View style={reportStyle.TextHeader}>
          <Text style={reportStyle.TextSubHeader}>Observations</Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            Overall, I found {FirstName}'s {rptData && rptData.Result}
          </Text>
        </View>
        {rptData && rptData.IsSkilledTherapyIndicated === false && (
          <>
            {nomsData && nomsData.length > 0 && (
              <>
                <View style={reportStyle.paragraph}>
                  <Text>{FirstName} was able to:</Text>
                  <View style={[reportStyle.tblnoms, { paddingLeft: 10 }]}>
                    {nomsData.map((row, index) => (
                      <View style={reportStyle.dataRownoms} key={row.RowId}>
                        <Text style={[reportStyle.indexCell]}>
                          {index + 1})
                        </Text>
                        <Text style={[reportStyle.questionCell97]}>
                          {`${
                            row.Question &&
                            row.Question.replace(/\?/g, '')
                              .charAt(0)
                              .toUpperCase()
                          }${
                            row.Question.endsWith('?')
                              ? row.Question.slice(1, -1)
                              : row.Question.slice(1)
                          } ${row.Answer}.`}
                        </Text>
                      </View>
                    ))}
                  </View>{' '}
                </View>
              </>
            )}
          </>
        )}
      </View>
    </>
  )
}

export default ContentCOG
