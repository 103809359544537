import React, { useState, useEffect } from 'react'
import { View, Text, Font } from '@react-pdf/renderer'
import { reportStyle } from '../CSS/reportStyle'
//import { CognitionService } from '../FCM/Cognition/CognitionService'
import { FeedingService } from '../FCM/Feeding/FeedingService'
import CheckedBoxIcon from './Component/CheckBoxIcon'
import { UnCheckedBoxIcon } from './Component/CheckBoxIcon'
import { HistoryOptions } from '../data/options'

Font.register({
  family: 'Roboto',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf',
})
Font.register({
  family: 'Doulos SIL',
  src: 'https://cdn.jsdelivr.net/npm/doulos-sil@5.000/fonts/DoulosSIL-R.ttf',
})
const capitalizeFirstChar = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

const ContentFEED = ({
  FirstName,
  HeOrShe,
  hisHer,
  userDetails,
  VisitId,
  FullName,
  HeaderFcm,
}) => {
  const [assSumdata, setAssSumdata] = useState([])
  const [rptData, setRptData] = useState(null)
  const [nomsData, setNomsData] = useState([])
  const service = new FeedingService()

  useEffect(() => {
    if (!VisitId) return
    //alert(Variables.API_URL + 'SpeechInt/GetAssSummary/' + VisitId+'/INT');
    service
      .getGetAssSummary(VisitId)
      .then((response) => {
        setAssSumdata(response)
        //alert(JSON.stringify(data));
      })
      .catch((error) => console.log('Error fetching data:', error))

    service
      .getFEEDData(VisitId)
      .then((response) => {
        //const data = response.data.Result
        //alert(JSON.stringify(response))
        if (response) {
          setRptData(response)
        }
      })
      .catch((error) => console.log('Error fetching data:', error))

    service
      .getNomsData(VisitId)
      .then((response) => {
        setNomsData(response)
      })
      .catch((error) => console.log('Error fetching data:', error))
  }, [VisitId])

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  return (
    <>
      <View style={reportStyle.pageSpace}>
        {HeaderFcm === true && (
          <>
            <View style={reportStyle.TextHeaderTop}>
              <Text style={reportStyle.TextHeaderText}>
                Clinical Assessment
              </Text>
            </View>
            <View style={reportStyle.paragraph}>
              <Text>
                {FirstName} was assessed against the following ASHA Functional
                Communication Measures (FCM):
              </Text>
            </View>
          </>
        )}
        <View style={reportStyle.TextHeader}>
          <Text style={reportStyle.TextHeaderText}>Feeding</Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            Based on the assessment & observations outlined herein, I found{' '}
            {FirstName}'s overall deficit to be{' '}
            {rptData && rptData.OverallRating}, and I believe Skilled Speech
            Therapy{' '}
            {rptData && rptData.IsSkilledTherapyIndicated ? 'is not' : 'is'}{' '}
            indicated for Feeding.
          </Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            I scored the following assessments when evaluating {hisHer} current
            Feeding proficiency:
          </Text>
        </View>
        <View style={reportStyle.container}>
          <View style={reportStyle.tblheaderRow}>
            <Text style={reportStyle.tbldataCellAssessment}>Assessment</Text>
            <Text style={reportStyle.tblheaderCell}>Raw Score</Text>
            <Text style={reportStyle.tblheaderCell}>Std Score</Text>
            <Text style={reportStyle.tblheaderCell}>%ile Rank</Text>
          </View>
          {assSumdata.map((row, index) => (
            <View style={reportStyle.tbldataRow} key={row.RowId}>
              <Text style={reportStyle.tbldataCellAssessment}>
                {row.AssessmentSummaryDesc}
              </Text>
              <Text style={reportStyle.tbldataCellNo}>
                {row.AssessmentSummary_Score}
              </Text>
              <Text style={reportStyle.tbldataCellNo}>
                {row.AssessmentSummary_Code}
              </Text>
              <Text style={reportStyle.tbldataCellNo}>
                {row.AssessmentSummary_Rank}
              </Text>
            </View>
          ))}
        </View>
        {/* {rptData && rptData.NonVerbal && (
          <>
            <View style={reportStyle.paragraph}>
              <Text>{FirstName} is currently Non-verbal.</Text>
            </View>
          </>
        )} */}
        <View style={reportStyle.TextHeader}>
          <Text style={reportStyle.TextSubHeader}>History</Text>
        </View>
        {rptData && rptData.HistoryDescription && (
          <>
            <View style={reportStyle.paragraph}>
              <Text>
                {capitalizeFirstLetter(hisHer)} history can be described as:{' '}
                {rptData && rptData.HistoryDescription}
              </Text>
            </View>
          </>
        )}

        <View style={reportStyle.paragraph}>
          <Text style={{ paddingBottom: 2 }}>
            I have identified the following concerns and/or diagnosis:
          </Text>
          <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            {HistoryOptions.map((row, index) => (
              <View style={{ width: '16.666%', marginBottom: 4 }} key={row.id}>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  {rptData &&
                  rptData.History &&
                  rptData.History.toLowerCase()
                    .split(',')
                    .includes(row.label.toLowerCase()) ? (
                    <CheckedBoxIcon />
                  ) : (
                    <UnCheckedBoxIcon />
                  )}

                  <Text style={{ marginLeft: 1, textAlign: 'left' }}>
                    {row.label}
                  </Text>
                </View>
              </View>
            ))}
          </View>
        </View>
        <View style={reportStyle.TextHeader}>
          <Text style={reportStyle.TextSubHeader}>
            Feeding Characteristics:
          </Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            {rptData &&
            (rptData.FoodAllergies === '' || rptData.FoodAllergies === null)
              ? `${FirstName} does not have any known food allergies.`
              : `${FirstName} is allergic to : ${rptData?.FoodAllergies || ''}`}
          </Text>
          {rptData && rptData.CurrentDiet && (
            <Text>
              {capitalizeFirstLetter(hisHer)} current diet consists of :{' '}
              {rptData.CurrentDiet}.
            </Text>
          )}
          {rptData && rptData.ResponseFood && (
            <Text>
              {capitalizeFirstLetter(hisHer)} overall response to food is :{' '}
              {rptData.ResponseFood}.
            </Text>
          )}
        </View>
        <View style={reportStyle.TextHeader}>
          <Text style={reportStyle.TextSubHeader}>Food Preferences:</Text>
        </View>
        <View style={reportStyle.paragraph}>
          {rptData && rptData.PreferredTemperature && (
            <Text>{rptData.FoodPreferences}.</Text>
          )}
          {rptData && rptData.PreferredTemperature && (
            <Text>Temperature : {rptData.PreferredTemperature}.</Text>
          )}
          {rptData && rptData.PreferredTexture && (
            <Text>Texture : {rptData.PreferredTexture}.</Text>
          )}
          {rptData && rptData.PreferredTaste && (
            <Text>Taste : {rptData.PreferredTaste}.</Text>
          )}
          {rptData && rptData.FoodPresentation && (
            <Text>Presentation : {rptData.FoodPresentation}.</Text>
          )}
        </View>
        <View style={reportStyle.TextHeader}>
          <Text style={reportStyle.TextSubHeader}>Food Dislikes:</Text>
        </View>
        <View style={reportStyle.paragraph}>
          {rptData && rptData.FoodDislikes && (
            <Text>{rptData.FoodDislikes}.</Text>
          )}
          {rptData && rptData.MealTimeObservation && (
            <Text>Mealtime Observations : {rptData.MealTimeObservation}.</Text>
          )}
          {rptData && rptData.CaregiverConcerns && (
            <Text>Caregiver Concerns : {rptData.CaregiverConcerns}.</Text>
          )}
          {rptData && rptData.AdditionalFinding && (
            <Text>Additional Findings : {rptData.AdditionalFinding}.</Text>
          )}
        </View>
        <View style={reportStyle.TextHeader}>
          <Text style={reportStyle.TextSubHeader}>Observations</Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            Overall, I found {FirstName}'s {rptData && rptData.Result}
          </Text>
        </View>
        {rptData && rptData.IsSkilledTherapyIndicated === false && (
          <>
            {nomsData && nomsData.length > 0 && (
              <>
                <View style={reportStyle.paragraph}>
                  <Text>{FirstName} was able to:</Text>
                  <View style={[reportStyle.tblnoms, { paddingLeft: 10 }]}>
                    {nomsData.map((row, index) => (
                      <View style={reportStyle.dataRownoms} key={row.RowId}>
                        <Text style={[reportStyle.indexCell]}>
                          {index + 1})
                        </Text>
                        <Text style={[reportStyle.questionCell97]}>
                          {`${
                            row.Question &&
                            row.Question.replace(/\?/g, '')
                              .charAt(0)
                              .toUpperCase()
                          }${
                            row.Question.endsWith('?')
                              ? row.Question.slice(1, -1)
                              : row.Question.slice(1)
                          } ${row.Answer}.`}
                        </Text>
                      </View>
                    ))}
                  </View>{' '}
                </View>
              </>
            )}
          </>
        )}
      </View>
    </>
  )
}

export default ContentFEED
