import React, { useState, useEffect } from 'react'
import { View, Text, Font } from '@react-pdf/renderer'
import { reportStyle } from '../CSS/reportStyle'
//import { CognitionService } from '../FCM/Cognition/CognitionService'
import { SwallowingService } from '../FCM/Swallowing/SwallowingService'
import CheckedBoxIcon from './Component/CheckBoxIcon'
import { UnCheckedBoxIcon } from './Component/CheckBoxIcon'
import {
  SymptomsOptions,   
} from '../data/options'

Font.register({
  family: 'Roboto',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf',
})
Font.register({
  family: 'Doulos SIL',
  src: 'https://cdn.jsdelivr.net/npm/doulos-sil@5.000/fonts/DoulosSIL-R.ttf',
})
const capitalizeFirstChar = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

const ContentSWAL = ({
  FirstName,
  HeOrShe,
  hisHer,
  userDetails,
  VisitId,
  FullName,
  HeaderFcm,
}) => {
  const [assSumdata, setAssSumdata] = useState([])
  const [rptData, setRptData] = useState(null)
  const [nomsData, setNomsData] = useState([])
  const [trialData, setTrialData] = useState([])
  const service = new SwallowingService()

  useEffect(() => {
    if (!VisitId) return
    //alert(Variables.API_URL + 'SpeechInt/GetAssSummary/' + VisitId+'/INT');
    service
      .getGetAssSummary(VisitId)
      .then((response) => {
        setAssSumdata(response)
        //alert(JSON.stringify(data));
      })
      .catch((error) => console.log('Error fetching data:', error))

    service
      .getSWALData(VisitId)
      .then((response) => {
        //const data = response.data.Result
        //alert(JSON.stringify(response))
        if (response) {
          setRptData(response)
        }
      })
      .catch((error) => console.log('Error fetching data:', error))

    service
      .getNomsData(VisitId)
      .then((response) => {
        setNomsData(response)
      })
      .catch((error) => console.log('Error fetching data:', error))
  }, [VisitId])

  return (
    <>
      <View style={reportStyle.pageSpace}>
        {HeaderFcm === true && (
          <>
            <View style={reportStyle.TextHeaderTop}>
              <Text style={reportStyle.TextHeaderText}>
                Clinical Assessment
              </Text>
            </View>
            <View style={reportStyle.paragraph}>
              <Text>
                {FirstName} was assessed against the following ASHA Functional
                Communication Measures (FCM):
              </Text>
            </View>
          </>
        )}
        <View style={reportStyle.TextHeader}>
          <Text style={reportStyle.TextHeaderText}>Swallowing</Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            Based on the assessment & observations outlined herein, I found{' '}
            {FirstName}'s overall deficit to be{' '}
            {rptData && rptData.OverallRating}, and I believe Skilled Speech
            Therapy{' '}
            {rptData && rptData.IsSkilledTherapyIndicated ? 'is not' : 'is'}{' '}
            indicated for Swallowing.
          </Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            I scored the following assessments when evaluating {hisHer} current
            Swallowing proficiency:
          </Text>
        </View>
        <View style={reportStyle.container}>
          <View style={reportStyle.tblheaderRow}>
            <Text style={reportStyle.tbldataCellAssessment}>Assessment</Text>
            <Text style={reportStyle.tblheaderCell}>Raw Score</Text>
            <Text style={reportStyle.tblheaderCell}>Std Score</Text>
            <Text style={reportStyle.tblheaderCell}>%ile Rank</Text>
          </View>
          {assSumdata.map((row, index) => (
            <View style={reportStyle.tbldataRow} key={row.RowId}>
              <Text style={reportStyle.tbldataCellAssessment}>
                {row.AssessmentSummaryDesc}
              </Text>
              <Text style={reportStyle.tbldataCellNo}>
                {row.AssessmentSummary_Score}
              </Text>
              <Text style={reportStyle.tbldataCellNo}>
                {row.AssessmentSummary_Code}
              </Text>
              <Text style={reportStyle.tbldataCellNo}>
                {row.AssessmentSummary_Rank}
              </Text>
            </View>
          ))}
        </View>
        {/* {rptData && rptData.NonVerbal && (
          <>
            <View style={reportStyle.paragraph}>
              <Text>{FirstName} is currently Non-verbal.</Text>
            </View>
          </>
        )} */}
        <View style={reportStyle.TextHeader}>
          <Text style={reportStyle.TextSubHeader}>History</Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>{rptData && rptData.History}</Text>
        </View>
        <View style={reportStyle.TextHeader}>
          <Text style={reportStyle.TextSubHeader}>Symptoms:</Text>
        </View>

        <View style={reportStyle.paragraph}>
          <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            {SymptomsOptions.map((row, index) => (
              <View style={{ width: '16.666%', marginBottom: 4 }} key={row.id}>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  {rptData &&
                  rptData.Symptoms &&
                  rptData.Symptoms.toLowerCase()
                    .split(',')
                    .includes(row.label.toLowerCase()) ? (
                    <CheckedBoxIcon />
                  ) : (
                    <UnCheckedBoxIcon />
                  )}

                  <Text style={{ marginLeft: 1, textAlign: 'left' }}>
                    {row.label}
                  </Text>
                </View>
              </View>
            ))}
          </View>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            {hisHer.charAt(0).toUpperCase() + hisHer.slice(1)} symptoms can be
            described as : {rptData && rptData.SymptomsDescription}
          </Text>
        </View>
        <View style={reportStyle.TextHeader}>
          <Text style={reportStyle.TextSubHeader}>Trial(s):</Text>
        </View>
        {rptData && rptData.SwalTrailSummary && (
          <>
            {/* {rptData && rptData.SwalTrailSummary}   */}
            <View style={reportStyle.tableT}>
              <View style={reportStyle.headerRowT}>
                <Text style={reportStyle.headerCellT}>Texture</Text>
                <Text style={reportStyle.headerCellT}>Presentation</Text>
                <Text style={reportStyle.headerCellT}>Size</Text>
                <Text style={reportStyle.headerCellT}>Result</Text>
              </View>
              {rptData.SwalTrailSummary?.map((item, index) => (
                <View key={index} style={reportStyle.dataRowT}>
                  <Text style={reportStyle.dataCellT}>{item.Texture}</Text>
                  <Text style={reportStyle.dataCellT}>{item.Presentation}</Text>
                  <Text style={reportStyle.dataCellT}>{item.Size}</Text>
                  <Text style={reportStyle.dataCellT}>{item.Result}</Text>
                </View>
              ))}
            </View>
          </>
        )}

        {rptData && rptData.CranialNerveAssessment && (
          <>
            <View style={reportStyle.TextHeader}>
              <Text style={reportStyle.TextSubHeader}>
                Cranial Nerve Assessment :{' '}
              </Text>
            </View>
            <View style={reportStyle.paragraph}>
              <Text>{rptData && rptData.CranialNerveAssessment}</Text>
            </View>
          </>
        )}
        {rptData && rptData.SafetyConcernsPrecaution && (
          <>
            <View style={reportStyle.TextHeader}>
              <Text style={reportStyle.TextSubHeader}>
                Safety Concerns and Precautions :{' '}
              </Text>
            </View>
            <View style={reportStyle.paragraph}>
              <Text>{rptData && rptData.SafetyConcernsPrecaution}</Text>
            </View>
          </>
        )}
        <View style={reportStyle.TextHeader}>
          <Text style={reportStyle.TextSubHeader}>Observations</Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            {hisHer.charAt(0).toUpperCase() + hisHer.slice(1)} current dietary
            mechanics are: Liquids - {rptData && rptData.CurrentDietLiquid},
            Solids - {rptData && rptData.CurrentDietSolid}, Medications -{' '}
            {rptData && rptData.CurrentDietMeds}.
          </Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            Mental status is {rptData && rptData.MentalStatus}, oral status is{' '}
            {rptData && rptData.OralStatus}, endurance is{' '}
            {rptData && rptData.Endurance}, respiratory function is{' '}
            {rptData && rptData.Respiratory}, and posture is{' '}
            {rptData && rptData.Posture}. Caregiver/patient{' '}
            {rptData && rptData.CaregiverPatientEducated === 'Yes'
              ? 'is '
              : 'is not'}{' '}
            educated.
          </Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            Overall, I found {FirstName}'s {rptData && rptData.Result}
          </Text>
        </View>
        {rptData && rptData.IsSkilledTherapyIndicated === false && (
          <>
            {nomsData && nomsData.length > 0 && (
              <>
                <View style={reportStyle.paragraph}>
                  <Text>{FirstName} was able to:</Text>
                  <View style={[reportStyle.tblnoms, { paddingLeft: 10 }]}>
                    {nomsData.map((row, index) => (
                      <View style={reportStyle.dataRownoms} key={row.RowId}>
                        <Text style={[reportStyle.indexCell]}>
                          {index + 1})
                        </Text>
                        <Text style={[reportStyle.questionCell97]}>
                          {`${
                            row.Question &&
                            row.Question.replace(/\?/g, '')
                              .charAt(0)
                              .toUpperCase()
                          }${
                            row.Question.endsWith('?')
                              ? row.Question.slice(1, -1)
                              : row.Question.slice(1)
                          } ${row.Answer}.`}
                        </Text>
                      </View>
                    ))}
                  </View>{' '}
                </View>
              </>
            )}
          </>
        )}
      </View>
    </>
  )
}

export default ContentSWAL
