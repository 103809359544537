import React, { useState, useContext, useEffect } from 'react'
import { GoalsSLEGeneral } from '../Component/GoalsSLEGeneral'
import { GTDefaultSLE } from '../../GoalComponenet/GTDefaultSLE'
import { Variables } from '../../Variables'
import axios from 'axios'
import {
  VisitProvider,
  SignLockProvider,
  CreatedByProvider,
} from '../../Provider/Visitprovider'
import { useLanguage } from '../../Provider/LanguageContext'
import { GoalsService } from '../Component/GoalsService'
import { toast } from 'react-toastify'

const apiUrl = Variables.API_URL
export const GoalsUI = React.forwardRef(
  ({ onDataFetched, refreshGoal, IsAdult }, ref) => {
    const VisitId = useContext(VisitProvider)
    const CreatedBy = useContext(CreatedByProvider)

    const [generalData, setGeneralData] = useState()
    const [selectedRowLT, setSelectedRowLT] = useState([])
    const [reFreshGenGrid, setReFreshGenGrid] = useState(true)
    const IsSignLock = useContext(SignLockProvider)
    const language = useLanguage()
    const [userLanguage, setuserLanguage] = useState(false)
    const goalService = new GoalsService()
    const [isDirty, setIsDirty] = useState(false)

    React.useImperativeHandle(ref, () => ({
      handleButtonClick,
    }))
    useEffect(() => {
      setuserLanguage(language.language)
    }, [VisitId])

    const handleRowSelected = (selectedRowData) => {
      setSelectedRowLT(selectedRowData)
    }

    const handleGeneralChange = (newData) => {
      setGeneralData(newData)
    }
    const validateLTGoalDescriptions = () => {
      const isEmpty = selectedRowLT?.some(
        (row) => !row.GoalDescription || row.GoalDescription.trim() === '',
      )
      return isEmpty
    }

    const handleButtonClick = (showMsg = true) => {
      //alert(isDirty)
      if (IsSignLock || !isDirty) {
        //alert(isDirty)
        return
      }
      if (validateLTGoalDescriptions() && showMsg) {
        toast.error('Please select the NOMS first to save the Goal(s).', {
          style: { width: '500px' },
        })
        return
      }

      setReFreshGenGrid(false)
      const apiCalls = []
      //alert("updatedsave : "+JSON.stringify(selectedRowLT));
      if (selectedRowLT != null) {
        apiCalls.push(goalService.saveData(selectedRowLT))
      }
      if (generalData && generalData.length > 0) {
        apiCalls.push(goalService.saveData(generalData))
      }

      // Promise.all([apiCalls])
      //   .then((responses) => {
      //     if (showMsg) {
      //       toast.success('Data saved successfully!')
      //     }
      //   })
      //   .catch((error) => {
      //     console.log('Failed to save data. Please try again.')
      //   })
      //   .finally(() => {
      //     setIsDirty(false)
      //   })
      Promise.all(apiCalls)  // No need to wrap apiCalls in another array, it's already an array
      .then((responses) => {
        // Log the responses to inspect them
        // console.log("Test check responses:", responses);
        // Assuming the response is in the first API call's result (responses[0])
        const response = responses[0];
        // console.log("test response:", response[0].data);
        if (response[0].data && response[0].data.StatusCode === 200) {
            toast.success( response[0].data.MessageDetail);
        } else {
          toast.error('Error: Data was not saved successfully');
          console.log('Error: Data was not saved successfully');
        }
        setReFreshGenGrid(true);
      })
      .catch((error) => {
        // Log the error if one of the promises fails
        console.log('Failed to save data. Please try again.', error);
      })
      .finally(() => {
        // Reset the dirty flag
        setIsDirty(false);
      });

      // setReFreshGenGrid(true)
    }

    return (
      <div style={{ display: 'block', width: '100%', padding: 10 }}>
        <table style={{ width: '100%', padding: 5 }}>
          <tr>
            <td>
              <GTDefaultSLE
                VisitId={VisitId}
                IsrefreshGoal={refreshGoal}
                onRowSelected={handleRowSelected}
                fcmType={'SLE'}
                CreatedBy={CreatedBy}
                IsChangeTracking={setIsDirty}
              />
            </td>
          </tr>

          <tr>
            <td>
              <GoalsSLEGeneral
                onTableDataChange={handleGeneralChange}
                reFreshGrid={reFreshGenGrid}
                VisitId={VisitId}
                IsAdult={IsAdult}
                CreatedBy={CreatedBy}
                fcmType={'SLE'}
                IsChangeTracking={setIsDirty}
              />
            </td>
          </tr>
          <tr>
            <td colspan={2} style={{ textAlign: 'right' }}>
              <div style={{ marginTop: '10px' }}>
                <button
                  disabled={IsSignLock || !isDirty}
                  onClick={handleButtonClick}
                  style={{ width: '150px' }}
                  className='btn btn-primary rounded-pill'
                >
                  <b> Save</b>{' '}
                </button>
              </div>
            </td>
          </tr>
        </table>
      </div>
    )
  },
)
