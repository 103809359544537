import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { AutocompleteDropdown } from '../../Controls/AutoCompleteDropdown'
import { DDList } from '../../Controls/DDList'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { Delete, Add } from '../../Icon/DeleteIcon'
import axios from 'axios'
import { Variables } from '../../Variables'
import { GoalsService } from './GoalsService'
import Loading from '../../Controls/Loading'

const apiUrl = Variables.API_URL

export const GoalsSLEGeneral = ({
  onTableDataChange,
  VisitId,
  reFreshGrid,
  hasData = false,
  IsAdult,
  fcmType,
  CreatedBy = null,
  IsChangeTracking = false,
}) => {
  const initialRow = {
    GoalId: 0,
    RowId: 1,
    VisitId: VisitId,
    FCMType: fcmType,
    GoalCategory: 'morphology',
    GoalType: 'General',
    Duration: 6,
    Units: 'mth(s)',
    GoalCode: 'ST',
    Given: 'no cues',
    InSessions: '4 of 5',
    Accuracy: '10%',
    GoalDescription: '',
    CreatedBy: CreatedBy,
    UpdatedBy: CreatedBy,
  }

  const [rows, setRows] = useState([initialRow])
  const [jsonQuestionData, setQuestionData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [goalCategory, setGoalCategory] = useState('morphology')
  const goalService = new GoalsService()

  useEffect(() => {
    if (reFreshGrid === true) {
      // alert(reFreshGrid)
      setIsLoading(true)
      goalService
        .getGoalsData(VisitId, fcmType)
        .then((response) => {
          const updatedRows = response.filter(
            (item) => item.GoalType === 'General',
          )
          if (updatedRows.length === 0) {
            setRows([initialRow])
          } else {
            const rowsWithUpdatedBy = updatedRows.map((row) => ({
              ...row,
              UpdatedBy: CreatedBy,
            }))
            setRows(rowsWithUpdatedBy)
            // setRows(updatedRows)
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [reFreshGrid])

  const removeDuplicateRows = (rows) => {
    const uniqueRowIds = new Set()
    return rows.filter((row) => {
      if (uniqueRowIds.has(row.RowId)) {
        return false
      }
      uniqueRowIds.add(row.RowId)
      return true
    })
  }

  useEffect(() => {
    onTableDataChange(rows)
  }, [rows, onTableDataChange])

  useEffect(() => {
    fetch('../data/goalSLE.json')
      .then((response) => response.json())
      .then((jsonData) => {
        const filteredData = jsonData.filter(
          (item) => item.category === goalCategory,
        )
        //alert(JSON.stringify(filteredData));
        setQuestionData(filteredData)
      })
      .catch((error) => console.log(error))
  }, [goalCategory])

  // const handleAddRow = () => {
  //   setRows((prevRows) => [
  //     ...prevRows,
  //     { ...initialRow, RowId: prevRows.length + 1 },
  //   ])
  //   setGoalCategory(initialRow.GoalCategory)
  //   //setRows(prevRows => removeDuplicateRows(prevRows));
  // }

  const handleAddRow = () => {
    setRows((prevRows) => {
      const newRow = { ...initialRow, RowId: rows[rows.length-1].RowId +1 };
      return [...prevRows, newRow]; // Only add the new row to the list
    });
    // console.log("test rows.length+1:",rows.length+1);
    // console.log("test rows:",rows);
    setGoalCategory(initialRow.GoalCategory)
  }

  const handleDeleteRow = (id, goalId) => {
    // alert(goalId);
    // console.log("test id:", id);
    // console.log("test goalId:", goalId);
    if (goalId > 0) {
      goalService.deleteGoal(goalId)
    }
    setRows((prevRows) => prevRows.filter((row) => row.RowId !== id))
    IsChangeTracking(true)
  }

  const handleTextboxChange = (rowId, columnName, value) => {
    setRows((prevRows) =>
      prevRows.map((row) =>
        row.RowId === rowId ? { ...row, [columnName]: value } : row,
      ),
    )
    IsChangeTracking(true)
  }

  const handleOptionSelect = (rowId, controlName, value) => {
    setRows((prevRows) =>
      prevRows.map((row) =>
        row.RowId === rowId ? { ...row, [controlName]: value } : row,
      ),
    )
    if (controlName === 'GoalCategory') {
      setGoalCategory(value)
    }
    IsChangeTracking(true)
  }
  if (isLoading) {
    return <Loading />
  }
  return (
    <div>
      <table className='table'>
        <thead>
          <tr>
            <th style={{ width: '2%' }}>Goal</th>
            <th style={{ width: '11%' }}>Category</th>
            <th style={{ width: '9%' }}>Within</th>
            <th style={{ width: '8%' }}>given</th>
            <th style={{ width: '50%' }}>the client</th>
            <th style={{ width: '9%' }}>with &#123;&#125; accuracy</th>
            <th style={{ width: '9%' }}>in &#123;&#125; sessions</th>
            <th style={{ width: '2%' }}>
              <button onClick={handleAddRow}>
                <Add></Add>
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr key={row.RowId}>
              <td style={{ width: '2%' }}>
                {row.GoalCode}-{index + 1}
              </td>
              <td style={{ width: '11%' }}>
                <DDList
                  ddType={32}
                  defaultValue={row.GoalCategory}
                  onSelect={(e) =>
                    handleOptionSelect(row.RowId, 'GoalCategory', e)
                  }
                />
              </td>
              <td style={{ width: '9%' }}>
                <Form>
                  <Form.Group as={Row}>
                    <Col sm={4}>
                      <input
                        type='text'
                        maxLength={2}
                        className='form-control form-control-sm'
                        value={row.Duration}
                        onChange={(e) =>
                          handleTextboxChange(
                            row.RowId,
                            'Duration',
                            e.target.value,
                          )
                        }
                      />
                    </Col>
                    <Col sm={8}>
                      <DDList
                        ddType={4}
                        defaultValue={row.Units}
                        onSelect={(e) =>
                          handleOptionSelect(row.RowId, 'Units', e)
                        }
                      />
                    </Col>
                  </Form.Group>
                </Form>
              </td>

              <td style={{ width: '8%' }}>
                <DDList
                  ddType='5'
                  defaultValue={row.Given}
                  onSelect={(e) => handleOptionSelect(row.RowId, 'Given', e)}
                />
              </td>
              <td style={{ width: '50%' }}>
                <Form>
                  <Form.Group controlId='autocomplete'>
                    <AutocompleteDropdown
                      options={jsonQuestionData}
                      initialValue={row.GoalDescription}
                      onSelect={(option) =>
                        handleOptionSelect(row.RowId, 'GoalDescription', option)
                      }
                    />
                  </Form.Group>
                </Form>
              </td>
              <td style={{ width: '9%' }}>
                <DDList
                  width='100'
                  ddType={8}
                  defaultValue={row.Accuracy}
                  onSelect={(e) => handleOptionSelect(row.RowId, 'Accuracy', e)}
                />
              </td>
              <td style={{ width: '9%' }}>
                <DDList
                  ddType={7}
                  defaultValue={row.InSessions}
                  onSelect={(e) =>
                    handleOptionSelect(row.RowId, 'InSessions', e)
                  }
                />
              </td>
              <td td style={{ width: '2%' }}>
                {
                index === rows.length - 1 && rows.length < 5 ? (
                  <button
                    onClick={() => handleDeleteRow(row.RowId, row.GoalId)}
                  >
                    <Delete />
                  </button>
                ) : (
                  <button
                    onClick={() => handleDeleteRow(row.RowId, row.GoalId)}
                  >
                    <Delete />
                  </button>
                )
                }
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
