import { format, isToday } from 'date-fns'

const formatDate = (date, includeToday = true, isUsFormat = false) => {
  // alert(`formated first if date:${date}`)

  if (!date) {
    return includeToday
      ? isUsFormat
        ? format(new Date(), 'MM-dd-yyyy')
        : format(new Date(), 'yyyy-MM-dd')
      : ''
  }

  if (includeToday && isToday(new Date(date))) {
    return isUsFormat
      ? format(new Date(), 'MM-dd-yyyy')
      : format(new Date(), 'yyyy-MM-dd')
  }

  const formattedDate = new Date(date).toLocaleDateString(
    isUsFormat ? 'en-US' : 'en-GB',
    {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    },
  )
  // .split("/")
  // .reverse()
  // .join("-");

  // alert(`formated before if date:${formattedDate}`)
  if (isUsFormat) {
    return formattedDate
  } else {
  // alert(`else case formated date:${formattedDate}`)

    return formattedDate.split('/').reverse().join('-')
  }
  return formattedDate
}

export function formatTime(dateString) {
  const date = new Date(dateString)
  let hours = date.getHours()
  const minutes = String(date.getMinutes()).padStart(2, '0')
  const period = hours >= 12 ? 'PM' : 'AM'

  // Convert to 12-hour format
  hours = hours % 12 || 12

  return `${hours}:${minutes} ${period}`
}

// export function formatTime(dateString) {
//   const date = new Date(dateString)
//   const hours = String(date.getHours()).padStart(2, '0')
//   const minutes = String(date.getMinutes()).padStart(2, '0')
//   return `${hours}:${minutes}`
// }
export default formatDate
