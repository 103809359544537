import React, { useState, useEffect, useContext } from 'react'
import { View, Text, Font } from '@react-pdf/renderer'
import { reportStyle } from '../CSS/reportStyle'
import formatDate from '../Utility/Utility'
import axios from 'axios'
import { Variables } from '../Variables'
import HeaderTextContent from './HeaderTextContent'
import { formatTime } from '../Utility/Utility'
import { AssessSumService } from '../FCM/Component/AssessSumService'
import { NomsService } from '../FCM/Component/NomsService'
import SpeechImage from './Component/SpeechImage'

Font.register({
  family: 'Roboto',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf',
})
Font.register({
  family: 'Doulos SIL',
  src: 'https://cdn.jsdelivr.net/npm/doulos-sil@5.000/fonts/DoulosSIL-R.ttf',
})

const fcmType = 'INT'
const ContentSI = ({
  FirstName,
  HeOrShe,
  hisHer,
  userDetails,
  VisitId,
  FullName,
  clientdata,
  pocData,
  Diagnosis2,
  HeaderFcm,
}) => {
  const [isYes] = useState('Yes')
  const [assSumdata, setAssSumdata] = useState([])
  const [sounData, setSoundData] = useState([])
  const [coreSidata, setCoreSiData] = useState(null)
  const [nomsData, setNomsData] = useState([])
  const assessSumService = new AssessSumService()
  const nomsService = new NomsService()

  useEffect(() => {
    if (!VisitId) return
    //alert(Variables.API_URL + 'SpeechInt/GetAssSummary/' + VisitId+'/INT');
    assessSumService
      .getGetAssSummary(VisitId, fcmType)
      .then((response) => {
        setAssSumdata(response)
        //alert(JSON.stringify(data));
      })
      .catch((error) => console.log('Error fetching data:', error))

    axios
      .get(Variables.API_URL + 'SpeechInt/GetSpeechInt/' + VisitId)
      .then((response) => {
        const data = response.data.Result
        //alert(data.IsSkilledSpeechTherapy)
        setCoreSiData(data)
      })
      .catch((error) => console.log('Error fetching data:', error))

    axios
      .get(Variables.API_URL + 'SpeechInt/GetSoundAss/' + VisitId)
      .then((response) => {
        const data = response.data.Result
        const filteredData = data.filter(
          (row) => row.Initial || row.Medial || row.Final,
        )
        setSoundData(filteredData)
        //alert(JSON.stringify(filteredData));
      })
      .catch((error) => console.log('Error fetching data:', error))

    nomsService
      .getNomsData(VisitId, fcmType)
      .then((response) => {
        setNomsData(response)
      })
      .catch((error) => {})
  }, [VisitId, userDetails])

  return (
    <>
      <View style={reportStyle.pageSpace}>
        {/* <View style={reportStyle.headerTextContainerText}>
        <Text style={reportStyle.headerText}>
          Date of Evaluation:{' '}
          {coreSidata && formatDate(coreSidata.CreatedDate, false, true)}
          {'   '}
          <Text style={reportStyle.headerTextSpace}> </Text>
          Start: {clientdata && formatTime(clientdata.Scheduleddatefrom)}{' '}
          {'   '}
          <Text style={reportStyle.headerTextSpace}> </Text>
          End: {clientdata && formatTime(clientdata.Scheduleddateto)} {'   '}
        </Text>
      </View> 
      <HeaderTextContent
        clientdata={clientdata}
        FullName={FullName}
        PocData={pocData}
        Diagnosis2={Diagnosis2}
      ></HeaderTextContent>*/}
        {HeaderFcm === true && (
          <>
            <View style={reportStyle.TextHeaderTop}>
              <Text style={reportStyle.TextHeaderText}>
                Clinical Assessment
              </Text>
            </View>
            <View style={reportStyle.paragraph}>
              <Text>
                {FirstName} was assessed against the following ASHA Functional
                Communication Measures (FCM):
              </Text>
            </View>
          </>
        )}
        <View style={reportStyle.TextHeader}>
          {' '}
          <Text style={reportStyle.TextHeaderText}>Speech Intelligibility</Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            Based on the assessment & observations outlined herein, I found{' '}
            {FirstName}'s overall deficit to be{' '}
            {coreSidata && coreSidata.OverallIntRating}, and I believe Skilled
            Speech Therapy{' '}
            {coreSidata && coreSidata.IsSkilledSpeechTherapy ? 'is not' : 'is'}{' '}
            indicated for Speech Intelligibility.
          </Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            I scored the following assessments when evaluating {FirstName}'s
            current speech & language proficiency:
          </Text>
        </View>
        <View style={reportStyle.container}>
          <View style={reportStyle.tblheaderRow}>
            <Text style={reportStyle.tbldataCellAssessment}>Assessment</Text>
            <Text style={reportStyle.tblheaderCell}>Raw Score</Text>
            <Text style={reportStyle.tblheaderCell}>Std Score</Text>
            <Text style={reportStyle.tblheaderCell}>%ile rank</Text>
          </View>
          {assSumdata.map((row, index) => (
            <View style={reportStyle.tbldataRow} key={row.RowId}>
              <Text style={reportStyle.tbldataCellAssessment}>
                {row.AssessmentSummaryDesc}
              </Text>
              <Text style={reportStyle.tbldataCellNo}>
                {row.AssessmentSummary_Score}
              </Text>
              <Text style={reportStyle.tbldataCellNo}>
                {row.AssessmentSummary_Code}
              </Text>
              <Text style={reportStyle.tbldataCellNo}>
                {row.AssessmentSummary_Rank}
              </Text>
            </View>
          ))}
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            Overall, I found {FirstName}{' '}
            {coreSidata && coreSidata.AssessmentDescription}.
          </Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            {coreSidata && coreSidata.AssessmentType && (
              <>
                Indication of {coreSidata.AssessmentType} of Prosody is
                suspected.
              </>
            )}
          </Text>
        </View>
        {sounData && sounData.length > 0 && (
          <View style={reportStyle.paragraph}>
            <Text>Sounds in Error:</Text>
          </View>
        )}
        <View style={reportStyle.soundContainer}>
          {sounData.map((row) => (
            <View style={reportStyle.cell} key={row.SI_SoundAssessmentId}>
              <Text style={reportStyle.customText}>
                {['ʤ', 'ʧ', 'ʃ'].includes(row.Sound) ? (
                  <SpeechImage sound={row.Sound} />
                ) : (
                  <>{row.Sound}</>
                )}{' '}
                - {row.Initial ? 'Initial, ' : ''}
                {row.Medial ? 'Medial, ' : ''}
                {row.Final ? 'Final' : ''}
              </Text>
            </View>
          ))}
        </View>
        {/* <View style={reportStyle.soundContainer}>
          {sounData.map((row, index) => (
            <View style={reportStyle.cell} key={row.SI_SoundAssessmentId}>
              <Text style={reportStyle.customText}>
                {row.Sound=='ʤ' || row.Sound=='ʤ' || row.Sound=='ʤ'  && <SpeechImage sound={row.Sound}/> else row.Sound} - {row.Initial ? 'Initial, ' : ''}
                {row.Medial ? 'Medial, ' : ''}
                {row.Final ? 'Final' : ''}
              </Text>
            </View>
          ))}
        </View> */}
        <View style={reportStyle.paragraph}>
          <Text>
            I found {hisHer} Sound Production Patterns to be{' '}
            {coreSidata && coreSidata.SPP_Level}
            {coreSidata && coreSidata.SPP_Type && (
              <> with {coreSidata.SPP_Type}</>
            )}
            {coreSidata && coreSidata.SPP_Description && (
              <>
                {' '}
                and the following sounds in error and/or production patterns:{' '}
                {coreSidata.SPP_Description}.{' '}
              </>
            )}
          </Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            I found {hisHer} Phonological Processes to be{' '}
            {coreSidata && coreSidata.PP_Level}
            {coreSidata && coreSidata.PP_Type && (
              <> with {coreSidata.PP_Type}</>
            )}{' '}
            {coreSidata && coreSidata.PP_Other && <> {coreSidata.PP_Other}</>}.
          </Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            I found {hisHer} Stimulability to be{' '}
            {coreSidata && coreSidata.Stimulability_Rating}.
          </Text>
        </View>
        {coreSidata && coreSidata.IsSkilledSpeechTherapy === false && (
          <View style={reportStyle.paragraph}>
            <Text>{FirstName} was able to:</Text>
            <View style={[reportStyle.tblnoms, { paddingLeft: 10 }]}>
              {nomsData.map((row, index) => (
                <View style={reportStyle.dataRownoms} key={row.RowId}>
                  <Text style={[reportStyle.indexCell]}>{index + 1})</Text>
                  <Text style={[reportStyle.questionCell97]}>
                    {row.Question.endsWith('?')
                      ? row.Question.replace(/\?/g, '')
                          .charAt(0)
                          .toUpperCase() + row.Question.slice(0, -1)
                      : row.Question.replace(/\?/g, '')
                          .charAt(0)
                          .toUpperCase() + row.Question.slice(1)}{' '}
                    {row.Answer}.
                  </Text>
                </View>
              ))}
            </View>
          </View>
        )}
        {/* <View style={reportStyle.paragraph}>
        <Text>{FirstName} was able to:</Text>
        <View style={[reportStyle.tblnoms, { paddingLeft: 10 }]}>
          {nomsData.map((row, index) => (
            <View style={reportStyle.dataRownoms} key={row.RowId}>
              <Text style={[reportStyle.indexCell]}>{index + 1})</Text>
              <Text style={[reportStyle.questionCell]}>
                {row.Question.endsWith('?')
                  ? row.Question.slice(0, -1)
                  : row.Question}{' '}
                {row.Answer}.
              </Text>
             
            </View>
          ))}
        </View>{' '}
      </View> */}
      </View>
    </>
  )
}

export default ContentSI
