//Local Env API
// export const Variables = {
//   API_URL:'http://localhost:65270/api/',
//   USER_API_URL: 'http://localhost:50930/api/',
// }


////UAT Env API NEW Test server'http://localhost:50930/api/',
// export const Variables = {
//   API_URL: 'https://uat-stnotesapi.slptele.com/api/',
//   USER_API_URL: 'https://uat-appapi.slptele.com/api/',
// }


// // Production Env API
export const Variables = {
  API_URL: 'https://stnotesapi.slptele.com/api/',
  USER_API_URL: 'https://appapi.slptele.com/api/',
}
