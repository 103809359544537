import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  lazy,
  Suspense,
} from 'react'
import { Variables } from '../../Variables'
import axios from 'axios'
import { VisitProvider } from '../../Provider/Visitprovider'
import { toast } from 'react-toastify'
import { SignLockProvider } from '../../Provider/Visitprovider'
import { NomsService } from '../Component/NomsService'
import { CreatedByProvider } from '../../Provider/Visitprovider'
import Loading from '../../Controls/Loading'
const apiUrl = Variables.API_URL
const NOMS = lazy(() => import('../Component/NOMS'))

export const NomsUI = React.forwardRef(
  ({ onDataFetched, triggerGoalRefresh, age,isActiveGoal=false }, ref) => {
    const VisitId = useContext(VisitProvider)
    const CreatedBy = useContext(CreatedByProvider)
    const [selectedRow, setSelectedRow] = useState([])
    const IsSignLock = useContext(SignLockProvider)
    const [isSaving, setIsSaving] = useState(false)
    const [shouldReloadData, setShouldReloadData] = useState(false)
    const [gridData, setGridData] = useState([])
    //const serviceNoms = new NomsService();
    const serviceNoms = useMemo(() => new NomsService(), [])
    const [loading, setLoading] = useState(true)
    const [isDirty, setIsDirty] = useState(false)
    const [isNomsSelected, setNomsSelected] = useState(false)

    React.useImperativeHandle(ref, () => ({
      handleButtonClick,
    }))
    useEffect(() => {
      //alert(VisitId);
      if(isNomsSelected)
      {
        isActiveGoal(true)
      }
    }, [isNomsSelected])
    
    useEffect(() => {
      var dataFile = 'data/nomsLC6.json'
      if (age < 6) {
        dataFile = 'data/nomsLC35.json'
      }

      fetch(dataFile)
        .then((response) => response.json())
        .then((data) => {
          setGridData(data)
        })
        .catch((error) => {
          console.error('Error reading data:', error)
        })
    }, [VisitId])

    const handleRowSelected = (selectedRowData) => {
      setSelectedRow(selectedRowData)
    }

    // useEffect(() => {
    //  handleButtonClick(false);
    // },[selectedRow]);

    useEffect(() => {
      if (VisitId !== null || shouldReloadData) {
        //alert(VisitId);
        serviceNoms
          .getNomsData(VisitId, 'SLC')
          .then((dataFromResponse) => {
            //alert("Data : "+JSON.stringify(dataFromResponse));
            setSelectedRow(dataFromResponse)
          })
          .catch((error) => {
            console.error('Error in CHA general:', error)
          })
          .finally(() => {
            setShouldReloadData(false)
            setLoading(false)
            onDataFetched()
          })
      }
    }, [VisitId, shouldReloadData, serviceNoms])

    const updateVisitId = (selectedRowData, newVisitId) => {
      return selectedRowData.map((row) => {
        let updatedRow = { ...row }
        if ('VisitId' in row && row.VisitId === 0) {
          //console.log("Updating VisitId");
          updatedRow = { ...updatedRow, VisitId: newVisitId }
        }
        if ('FCMType' in row) {
          //console.log("Updating FCMType");
          updatedRow = { ...updatedRow, FCMType: 'SLC' }
        }
        if (('CreatedBy' in row)) {
          updatedRow = { ...updatedRow, CreatedBy: CreatedBy }
        }
        updatedRow = { ...updatedRow, UpdatedBy: CreatedBy }
        return updatedRow
      })
    }

    const handleButtonClick = (showMsg = true) => {
      //alert('is change dirty : ' + isDirty)
      if (IsSignLock || !isDirty) {
        return
      }

      if (gridData != null && !isSaving) {
        setIsSaving(true)
        const updatedSelectedRow = updateVisitId(gridData, VisitId)
        // alert(JSON.stringify(updatedSelectedRow));
        // return;

        serviceNoms
          .saveData(updatedSelectedRow)
          .then((response) => {
            if (showMsg) {
              // alert('Record has been saved.')
              toast.success(response[0]?.data?.MessageDetail)

            }
            triggerGoalRefresh()
            setShouldReloadData(true)
          })
          .catch((error) => {
            console.error(
              'Error : .saveData(updateAssmentSummary,formData)',
              error,
            )
          })
          .finally(() => {
            setIsSaving(false)
            setIsDirty(false)
          })
      }
    }
    const updateGridData = (newGridData) => {
      if (newGridData && newGridData.length > 0) {
        setGridData(newGridData)
      }
    }
    if (loading) {
      return <Loading />
    }
    return (
      <div style={{ display: 'block', width: 'max', padding: 10 }}>
        <table style={{ width: '100%', padding: 5 }}>
          {age < 6 ? (
            <tr>
              <td style={{ verticalAlign: 'top' }}>
                <p style={{ fontSize: '13px' }}>
                  <b>Instructions :</b> Based on your assessment, please answer
                  the following questions about the child's spoken language
                  comprehension skills in low and high demand situations. For
                  all questions, consider chronological age expectations when
                  rating the child.{' '}
                  <i>
                    <br /> <b>• Low demand situations</b> are defined as verbal
                    interactive situations where communication demands are
                    reduced. (e.g., familiar setting, familiar conversation
                    partner, known context).
                    <br />
                    <b>• High demand situations</b> are defined as verbal
                    interactive situations that provide minimal support and have
                    inherently more communication demands or rules to follow.
                    (e.g., unfamiliar conversational partner or setting, unknown
                    contexts, group conversation).
                  </i>
                </p>
              </td>
            </tr>
          ) : (
            <tr>
              <td style={{ verticalAlign: 'top' }}>
                <p style={{ fontSize: '13px' }}>
                  <b>Instructions :</b> Based on your assessment, please answer
                  the following questions about the individual's spoken language
                  comprehension skills in low and high demand situations. For
                  all questions, consider chronological age expectations when
                  rating the individual.
                  <br />{' '}
                  <i>
                    {' '}
                    <b>• Low demand situations</b> are defined as verbal
                    interactive situations where communication demands are
                    reduced. (e.g., familiar setting, familiar conversation
                    partner, known context) For children this may include
                    language content and forms acquired at a younger age than
                    the child’s current age. <br />
                    <b>• High demand situations</b> are defined as verbal
                    interactive situations that provide minimal support and have
                    inherently more communication demands or rules to follow.
                    (e.g., unfamiliar conversational partner or setting, unknown
                    contexts, group conversation) For children this may include
                    language content and forms representing more recently
                    acquired or expected structures for the child’s current
                    chronological age.
                  </i>{' '}
                </p>
              </td>
            </tr>
          )}

          <tr>
            <td>
              <Suspense fallback={<Loading />}>
                <NOMS
                  VisitId={VisitId}
                  gridData={gridData}
                  defaultRow={selectedRow}
                  onRowSelected={handleRowSelected}
                  updateGridData={updateGridData}
                  isChangedTracker={setIsDirty}
                  isNomsSelected={setNomsSelected}     
                />
              </Suspense>
            </td>
          </tr>
          <tr>
            <td colSpan={2} style={{ textAlign: 'right' }}>
              <div style={{ marginTop: '10px' }}>
                <button
                  style={{ width: '150px' }}
                  disabled={IsSignLock || !isDirty || isSaving}
                  onClick={handleButtonClick}
                  className='btn btn-primary rounded-pill'
                >
                  {isSaving ? <b>Saving...</b> : <b>Save</b>}
                </button>
              </div>
            </td>
          </tr>
        </table>
      </div>
    )
  },
)
