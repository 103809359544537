import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import { TextareaComponent } from '../../Controls/TextareaComponent '
import { VisitProvider, SignLockProvider } from '../../Provider/Visitprovider'
import { useUser } from '../../Provider/UserContext'
import { ProgressNoteService } from './ProgressNoteService'
import { HeaderUI } from './HeaderUI'
import { CProgressReportUI } from '../CProgressReportUI'
import { SelectYesNo } from '../../Controls/DDLYesNo'
import { Checkbox } from '../../Controls/CheckBoxList'
import { CheckboxListInColumn } from '../../Controls/CheckBoxList'
import { DDList } from '../../Controls/DDList'
// import AlertifyMessage from "../../AlertifyMessage";
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ProgressNotes } from '../../Enum'
import HorizontalLine from '../../Controls/HorizontalLine'
import { GoalService } from '../../GoalComponenet/GoalService'
import signLockService from '../../POC/SignLock/Service'
import { VisitTypeId, NoteType } from '../../Enum'
import ProgressNoteMain from '../../PDFGenerator/NotesPDF/ProgressNoteMain'
import format from 'date-fns/format'
import {
  CreatedByProvider,
  UserTypeIdProvider,
} from '../../Provider/Visitprovider'
import ProgNotesPDFDownload from '../../PDFGenerator/NotesPDF/ProgNotesPDFDownload'
import { useSelector } from 'react-redux'

const underlineStyle = {
  textDecoration: 'underline',
}
export const ProgressNoteUI = forwardRef((refreshSignLock, ref) => {
  const supervisorDetail = useSelector((state) => state.supervisorDetail)
  const { userFirstName } = useUser()
  const { userDetails } = useUser()
  const VisitId = useContext(VisitProvider)
  const userTypeId = useContext(UserTypeIdProvider)
  const [clientId, setClientId] = useState(null)
  const [IsSignLock, setIsSignLock] = useState(false)
  const progressNoteService = new ProgressNoteService()
  const [loadingHeader, setLoadingHeader] = useState(true)
  const [loadingObjectiveFinding, setLoadingObjectiveFinding] = useState(true)
  const [stGoalData, setStGoalData] = useState([])
  const [refreshKey, setRefreshKey] = useState(0)
  const [isDirty, setIsDirty] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const CreatedBy = useContext(CreatedByProvider)

  const [ProgramEstablishDescError, setProgramEstablishDescError] =
    useState(false)
  const [CaregiverDescError, setCaregiverDescError] = useState(false)
  const [PrognosisDescError, setPrognosisDescError] = useState(false)
  const [
    ReceivingServicesOutsideDescError,
    setReceivingServicesOutsideDescError,
  ] = useState(false)
  const [ProgressReportError, setProgressReportError] = useState(false)
  const [
    JustificationContinuedTherapyError,
    setJustificationContinuedTherapyError,
  ] = useState(false)
  const [formData, setFormData] = useState(() => {
    const progressNoteService = new ProgressNoteService()
    progressNoteService.VisitId = VisitId // Set the VisitId property here
    progressNoteService.ClientId = clientId
    progressNoteService.NoteType = ProgressNotes.ProgressNote
    return progressNoteService
  })
  const headerUIRef = useRef()
  const goalService = new GoalService()
  useEffect(() => {
    if (userDetails) {
      setClientId(userDetails.Clientid)
      formData.ClientId = userDetails.Clientid
      formData.supervisorDetail = supervisorDetail
    }
  }, [userDetails, VisitId])

  const FetchingSignAndLock = () => {
    signLockService
      .getSignLockDataByNoteType(VisitId, NoteType.ProgressNote)
      .then((response) => {
        if (response) {
          let isSignedAndLocked = response.IsSignedAndLocked
          isSignedAndLocked = isSignedAndLocked || false
          //if (response.IsProgressNote === true) {
          setIsSignLock(isSignedAndLocked)
          // }
        }
        // alert("hiih");
      })
      .catch((error) => {
        console.error('Error FetchingSignAndLock:', error)
      })
  }
  useEffect(() => {
    if (VisitId) {
      FetchingSignAndLock()
    }
  }, [VisitId])

  const handleSTDataChange = (newData) => {
    setStGoalData(newData)
    //alert("Goaldata: "+JSON.stringify(newData));
  }
  useEffect(() => {
    //alert(JSON.stringify(supervisorDetail));
    if (clientId !== null) {
      const fetchData = () => {
        progressNoteService
          .getProgressNotes(VisitId, clientId)
          .then((dataFromResponse) => {
            if (dataFromResponse.ProgressDischargeId === 0) {
              formData.Frequency = dataFromResponse.Frequency
              formData.Duration = dataFromResponse.Duration
              formData.PNGoalListItems = dataFromResponse.PNGoalListItems
              setFormData(formData)
            } else {
              setFormData(dataFromResponse)
            }
            //setFormData(dataFromResponse);
            setLoadingHeader(false)
            setLoadingObjectiveFinding(false)
          })
          .catch((error) => {
            console.error('Error fetching PocDTO data:', error)
            setLoadingHeader(false)
            setLoadingObjectiveFinding(false)
          })
      }
      fetchData()
    }
  }, [clientId, VisitId, refreshKey])

  const handleCheckboxChange = (columnName, checked) => {
    const updatedFormData = { ...formData, [columnName]: checked }
    setFormData(updatedFormData)
  }

  const handleTextValueChange = (controlName, value) => {
    const valueControl = controlName
    const updatedFormData = {
      ...formData,
      [valueControl]: value,
    }
    setFormData(updatedFormData)
  }
  // const handleSelectedRowsChange = (selectedRows) => {
  //   const filteredData = stGoalData.filter(
  //     (item) => item.GoalType !== "LT" && !selectedRows.some((row) => row.GoalId === item.GoalId)
  //   );
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     PNGoalListItems: filteredData,
  //   }));
  // };
  const insertData = (selectedRow) => {
    goalService
      .updateGoalMet(selectedRow)
      .then((dataFromResponse) => {
        // Handle the success response, if needed
      })
      .catch((error) => {
        console.error('Header UI error', error)
      })
  }

  const ValidationObject = () => {
    let isValid = true
    if (
      formData.ProgramEstablishDesc === null ||
      formData.ProgramEstablishDesc.trim() === ''
    ) {
      setProgramEstablishDescError(true)
      isValid = false
    }
    if (
      formData.CaregiverDesc === null ||
      formData.CaregiverDesc.trim() === ''
    ) {
      setCaregiverDescError(true)
      isValid = false
    }

    if (
      formData.Prognosis === 'Guarded' &&
      (formData.PrognosisDesc === null || formData.PrognosisDesc.trim() === '')
    ) {
      setPrognosisDescError(true)
      isValid = false
    }
    if (
      formData.IsReceivingServicesOutside === 'Yes' &&
      (formData.ReceivingServicesOutsideDesc === null ||
        formData.ReceivingServicesOutsideDesc.trim() === '')
    ) {
      setReceivingServicesOutsideDescError(true)
      isValid = false
    }
    if (
      formData.ProgressReport === null ||
      formData.ProgressReport.trim() === ''
    ) {
      setProgressReportError(true)
      isValid = false
    }

    if (
      // formData.IsReadyDischargeAuthorization === true &&
      formData.JustificationContinuedTherapy === null ||
      formData.JustificationContinuedTherapy.trim() === ''
    ) {
      setJustificationContinuedTherapyError(true)
      isValid = false
    }

    //alert(isValid);
    return isValid
  }
  useImperativeHandle(ref, () => ({
    handleButtonClick,
  }))
  const handleButtonClick = (showAlert = true) => {
    if (showAlert === false) {
      FetchingSignAndLock()
    }
    if (IsSignLock === true) {
      return
    }
    setIsSaving(true)

    const goalDatarows = headerUIRef.current.getSelectedRows()
    const updatedData = stGoalData.map((item) => {
      const matchingRow = goalDatarows.find((row) => row.GoalId === item.GoalId)
      if (matchingRow) {
        return {
          ...item,
          ClientId: clientId,
          GoalMET: matchingRow.GoalMET,
          GoalMetDate: matchingRow.GoalMetDate,
        }
      }
      return item
    })

    //alert(JSON.stringify(updatedData));
    //return;
    goalDatarows.forEach((row) => {
      //alert(JSON.stringify(row));
      insertData(row)
    })
    //alert(JSON.stringify(goalDatarows));
    //return;
    if (updatedData) {
      //alert("metData:"+JSON.stringify(goalDatarows));
      const filteredData = updatedData.filter((item) => item.GoalType !== 'LT')
      //alert("filteredData:" + JSON.stringify(filteredData));
      formData.PNGoalListItems = filteredData
    }

    // const isValid = ValidationObject();
    // //alert(isValid);
    // if (!isValid) {
    //   toast.error("Kindly fill the all required field(s).");
    //   return;
    // }
    // if(formData.PNGoalListItems.length===0)
    // {
    //   toast.error("Seems you have not filled the soapnote or not perform the sign/lock the soapnote for this visit.");
    //   return;
    // }
    formData.ClientId = clientId
    formData.CreatedBy = CreatedBy
    formData.UpdatedBy = CreatedBy
    progressNoteService
      .insertProgressNote(formData)
      .then((response) => {
        // Handle the API call success response here
        formData.ProgressDischargeId = response.Result
        setRefreshKey((prevKey) => prevKey + 1)
        if (showAlert) {
          toast.success(response.MessageDetail)
        }
      })
      .catch((error) => {
        // Handle the API call error or toast error message here
        toast.error('Error in API call!')
      })
      .finally(() => {
        setIsSaving(false)
      })
  }

  const handleSelect = (selectedValue, dropdownName) => {
    const valueControl = dropdownName
    const updatedFormData = {
      ...formData,
      [valueControl]: selectedValue,
    }
    setFormData(updatedFormData)
  }
  const handleCheckboxListChange = (controlName, value) => {
    const valueControl = controlName
    const updatedFormData = {
      ...formData,
      [valueControl]: value,
    }
    setFormData(updatedFormData)
  }

  const [pdfIsReadyforUpload, setPdfIsReadyforUpload] = useState(false)
  const [isSuccessful, setIsSuccessful] = useState(false)
  const [confirmSignLock, setConfirmSignLock] = useState(false)
  const [buttonText, setButtonText] = useState('Sign and Lock')
  const [isProcessing, setIsProcessing] = useState(false)

  const [regenerateReportButton, setRegenerateReportButton] =
    useState('Generate Report')
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)

  const handleSignLockConfirmationClick = () => {
    const today = new Date()
    const scheduledDate = new Date(userDetails.Scheduleddatefrom)

    if (scheduledDate > today) {
      alert('Future visit cannot be signed and locked.')
      return
    }

    const confirmed = window.confirm('Are you sure you want to sign and lock?')
    if (confirmed) {
      setPdfIsReadyforUpload(true)
      setConfirmSignLock(true)
      setPdfIsReadyforUpload(true)
      setIsProcessing(true)
      setButtonText('in process...')
    } else {
      setConfirmSignLock(false)
      toast.warn('Sign and lock process has been cancelled.', {
        className: 'custom-toast',
        style: { width: '400px' },
      })
    }
  }

  const handlePdfReGenerationComplete = () => {
    setRegenerateReportButton('Generating Report...')
    setIsButtonDisabled(true)

    setTimeout(() => {
      setPdfIsReadyforUpload(true)
      setRegenerateReportButton('Regenerate Report')
      setIsButtonDisabled(false)
      alert('Report generated successfully!') // Move alert inside setTimeout
    }, 12000)
  }

  const handlePdfGenerationComplete = (success) => {
    // alert(success)
    setIsSuccessful(success)
    if (!success) {
      setIsProcessing(false)
      setPdfIsReadyforUpload(false)
      setButtonText('Sign and Lock')
      toast.error(
        'An error occurred with the sign and lock process. Please try again.',
        {
          className: 'custom-toast',
          style: { width: '550px' },
        },
      )
    }
  }

  const handleSignLockClick = () => {
    // const isValid = ValidationObject();
    // //alert(isValid);
    // if (!isValid) {
    //   toast.error("Kindly fill the all required field(s).");
    //   return;
    // }
    //const confirmed = window.confirm('Are you sure you want to sign and lock?')
    if (confirmSignLock) {
      const signLockData = {
        VisitId: VisitId,
        IsSignedAndLocked: true,
        ClientId: clientId,
        VisitTypeId: userDetails.VisitTypeId, // VisitTypeId.SoapNote,
        TherepistId: userDetails.TherapistId,
        VisitStartDateTime: userDetails.Scheduleddatefrom,
        VisitendDateTime: userDetails.Scheduleddateto,
        IsProgressNote: true,
        SignedBy: userDetails.TherapistId,
        NoteType: NoteType.ProgressNote,
      }

      const saveSlpCoreSignLock = {
        VisitId: VisitId,
        SignedBy: userDetails.TherapistId,
        DateAndTimeSigned: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
        VisitDateFrom: userDetails.Scheduleddatefrom,
        VisitDateTo: userDetails.Scheduleddateto,
        HasProgressNote: 1,
        UpdateBy: userDetails.TherapistId,
        UpdateDate: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
        NotesVersion: 3,
      }
      // alert("signlock:"+JSON.stringify(signLockData));
      //return;
      try {
        // Call handleButtonClick, but ignore alerts and messages from it
        handleButtonClick(false)
      } catch (error) {
        // Handle any potential errors from handleButtonClick here
        console.error('Error calling handleButtonClick:', error)
      }
      signLockService
        .insertSignLockDataSLPCore(saveSlpCoreSignLock)
        .then((response) => {
          //alert("Sign and lock confirmed!");
        })
        .catch((error) => {
          //console.error("Error inserting data:", error);
        })

      signLockService
        .insertSignLockData(signLockData)
        .then((response) => {
          setIsSignLock(true)
          //refreshSignLock();
          alert('Sign and lock confirmed!')
        })
        .catch((error) => {
          console.error('Error inserting data:', error)
        })
        .finally(() => {
          setIsProcessing(false)
          setPdfIsReadyforUpload(false)
          setButtonText('Sign and Lock')
        })
    } else {
      // Perform any action needed when the user clicks "Cancel" in the confirmation dialog
      alert('Sign and lock cancelled.')
    }
  }

  useEffect(() => {
    if (isSuccessful) {
      //
      handleSignLockClick()
      setPdfIsReadyforUpload(false)
    }
  }, [isSuccessful])

  useEffect(() => {
    //alert("form data");
    ValidationObject()
  }, [formData])
  return (
    <Container fluid>
      <Row className='mt-1'>
        <Row>
          <Col md={12}>
            <b style={underlineStyle}>{userFirstName}'s Therapy Goals</b>
          </Col>
        </Row>

        <Row>
          {loadingHeader ? (
            <p>Loading...</p>
          ) : (
            <HeaderUI
              ref={headerUIRef}
              firstName={userFirstName}
              onSaveButtonClick={handleButtonClick}
              VisitId={VisitId}
              ClientId={clientId}
              //onSelectedRowsChange={handleSelectedRowsChange}
            />
          )}
        </Row>
      </Row>
      <Row>
        <Col md={12}>
          <HorizontalLine></HorizontalLine>{' '}
        </Col>
      </Row>
      <Row>
        <b style={underlineStyle}>Progress Toward Goals</b>
      </Row>
      <Row className='mt-1'>
        {loadingObjectiveFinding ? (
          <p>Loading...</p>
        ) : (
          <CProgressReportUI
            onTableDataChange={handleSTDataChange}
            firstName={userFirstName}
            dataSource={formData.PNGoalListItems}
            IsChangeTracking={setIsDirty}
          />
        )}
      </Row>
      <Row>
        <Col md={12}>
          <HorizontalLine></HorizontalLine>{' '}
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Row>
            <Col md={4}>
              <b>Home program established?</b>
            </Col>
            <Col md={2}>
              <SelectYesNo
                defaultValue={formData.ProgramEstablish}
                onSelect={(selectedValue) =>
                  handleSelect(selectedValue, 'ProgramEstablish')
                }
              />
            </Col>
            <Col md={6}>
              <TextareaComponent
                IsChangeTracking={setIsDirty}
                cols={40}
                showError={ProgramEstablishDescError}
                rows={1}
                maxLength={5000}
                defaultValue={formData.ProgramEstablishDesc}
                onTextChange={(value) =>
                  handleTextValueChange('ProgramEstablishDesc', value)
                }
                placeholder={'Please describe..., Why?'}
              />
            </Col>
          </Row>
          <Row className='mt-1'>
            <Col md={4}>
              <b>Caregiver/Coaching Involvement?</b>
            </Col>
            <Col md={2}>
              <SelectYesNo
                defaultValue={formData.Caregiver}
                onSelect={(selectedValue) =>
                  handleSelect(selectedValue, 'Caregiver')
                }
              />
            </Col>
            <Col md={6}>
              <TextareaComponent
                IsChangeTracking={setIsDirty}
                showError={CaregiverDescError}
                cols={40}
                rows={1}
                maxLength={5000}
                defaultValue={formData.CaregiverDesc}
                onTextChange={(value) =>
                  handleTextValueChange('CaregiverDesc', value)
                }
                placeholder={'Please describe..., Why?'}
              />
            </Col>
          </Row>
          <Row className='mt-1'>
            <Col md={4}>
              <b>Prognosis:</b>
            </Col>
            <Col md={2}>
              <DDList
                onSelect={(selectedValue) =>
                  handleSelect(selectedValue, 'Prognosis')
                }
                defaultValue={formData.Prognosis}
                ddType={26}
              />
            </Col>
            <Col md={6}>
              <TextareaComponent
                IsChangeTracking={setIsDirty}
                showError={PrognosisDescError}
                cols={40}
                rows={1}
                maxLength={5000}
                defaultValue={formData.PrognosisDesc}
                onTextChange={(value) =>
                  handleTextValueChange('PrognosisDesc', value)
                }
                placeholder={'Please explain barriers to progress...'}
              />
            </Col>
          </Row>

          <Row className='mt-1'>
            <Col md={4}>
              <b>Receiving outside services?</b>
            </Col>
            <Col md={2}>
              <SelectYesNo
                defaultValue={formData.IsReceivingServicesOutside}
                onSelect={(selectedValue) =>
                  handleSelect(selectedValue, 'IsReceivingServicesOutside')
                }
              />
            </Col>
            <Col md={6}>
              {formData.IsReceivingServicesOutside === 'Yes' && (
                <TextareaComponent
                  IsChangeTracking={setIsDirty}
                  showError={ReceivingServicesOutsideDescError}
                  cols={40}
                  rows={1}
                  maxLength={50}
                  defaultValue={formData.ReceivingServicesOutsideDesc}
                  onTextChange={(value) =>
                    handleTextValueChange('ReceivingServicesOutsideDesc', value)
                  }
                  placeholder={'Where...'}
                />
              )}
            </Col>
          </Row>

          <Row className='mt-1'>
            <Col md={4}>
              <b>Client Participation:</b>
            </Col>
            <Col md={2}>
              <DDList
                onSelect={(selectedValue) =>
                  handleSelect(selectedValue, 'ClientParticipation')
                }
                defaultValue={formData.ClientParticipation || '76-100%'}
                ddType={20}
              />
            </Col>
            <Col md={6}>
              <b>Frequency/Duration: </b> {formData.Frequency} times per week
              for {formData.Duration} weeks
            </Col>
          </Row>
          <Row className='mt-1'>
            <Col md={4}>
              <b>Client Attendance: </b>
            </Col>
            <Col md={2}>
              <DDList
                onSelect={(selectedValue) =>
                  handleSelect(selectedValue, 'ClientAttendance')
                }
                defaultValue={formData.ClientAttendance || '76-100%'}
                ddType={20}
              />
            </Col>
            <Col md={6}>Key: 3+ High, 2 Moderate, 1 Low</Col>
          </Row>
          {/* <Row className="mt-1">
            <Col md={12}>
              <b>Frequency/Duration: </b> {formData.Frequency} times per week
              for {formData.Duration} weeks
            </Col>
          </Row> */}
          <Row className='mt-1'>
            <Col md={12}>
              <b>Overall progress since last report:</b>
            </Col>
            <Col md={12} className='mt-1'>
              <TextareaComponent
                IsChangeTracking={setIsDirty}
                cols={40}
                rows={2}
                showError={ProgressReportError}
                maxLength={5000}
                defaultValue={formData.ProgressReport}
                onTextChange={(value) =>
                  handleTextValueChange('ProgressReport', value)
                }
                placeholder={'Please describe...'}
              />
            </Col>
          </Row>
        </Col>
        <Col md={6}>
          <Row>
            <Col md={5}>
              <b>
                <span style={{ color: 'red' }}>*</span> Justification for
                continued therapy:
              </b>
            </Col>{' '}
            <Col md={7}>
              <Checkbox
                label='Ready for discharge after current authorization?'
                checked={formData.IsReadyDischargeAuthorization}
                onChange={handleCheckboxChange}
                columnName='IsReadyDischargeAuthorization'
              />{' '}
            </Col>
          </Row>
          <Row className='mt-1'>
            <Col md={12}>
              <TextareaComponent
                IsChangeTracking={setIsDirty}
                showError={JustificationContinuedTherapyError}
                cols={40}
                rows={2}
                maxLength={5000}
                defaultValue={formData.JustificationContinuedTherapy}
                onTextChange={(value) =>
                  handleTextValueChange('JustificationContinuedTherapy', value)
                }
                placeholder={'Please describe...'}
              />
            </Col>
          </Row>
          <Row className='mt-2'>
            <b>Referral Needed?</b>
          </Row>
          <Row className='mt-1'>
            <CheckboxListInColumn
              values={formData.ReferralNeeded || ''}
              onChange={(selectedValues) =>
                handleCheckboxListChange('ReferralNeeded', selectedValues)
              }
              typeOption={8}
              columns={4}
              rows={4}
            ></CheckboxListInColumn>
          </Row>
          <Row className='mt-1'>
            <Col md={12}>
              <TextareaComponent
                IsChangeTracking={setIsDirty}
                cols={40}
                rows={2}
                maxLength={1000}
                defaultValue={formData.ReferralDescription}
                onTextChange={(value) =>
                  handleTextValueChange('ReferralDescription', value)
                }
                placeholder={'Please describe...'}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {/* <AlertifyMessage /> */}
      <Row className='mt-3'>
        <Col md={12} className='text-right'>
          <button
            onClick={handleButtonClick}
            disabled={IsSignLock || isSaving || !isDirty}
            style={{ width: '150px', marginRight: '20px' }}
            className='btn btn-primary rounded-pill'
          >
            {isSaving ? <b>Saving...</b> : <b>Save</b>}
          </button>

          {/* <button
            onClick={handleSignLockConfirmationClick}
            disabled={IsSignLock || isProcessing}
            style={{ width: '150px', marginRight: '20px' }}
            className='btn btn-primary rounded-pill'
          >
            <b>{buttonText}</b>{' '}
          </button> */}
          <div
            style={{
              marginTop: '2px',
              display: 'inline-block',
              verticalAlign: 'middle',
            }}
          >
            <ProgressNoteMain data={formData} />
            {/* {formData && <PdfGenerateNotes pSummaryData={formData} />} */}
          </div>
          {pdfIsReadyforUpload && (
            <ProgNotesPDFDownload
              onPdfGenerationComplete={handlePdfGenerationComplete}
              pSummaryData={formData}
            />
          )}
          {/* {IsSignLock && userTypeId === 3 && (
            <button
              onClick={handlePdfReGenerationComplete}
              style={{ width: '180px', marginLeft: '10px' }}
              className='btn btn-primary rounded-pill'
              disabled={isButtonDisabled}
            >
              <b>{regenerateReportButton}</b>
            </button>
          )} */}
        </Col>
      </Row>
    </Container>
  )
})
